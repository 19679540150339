/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { Suspense, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, matchPath } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import ProximityPage from "layouts/pages/landing-pages/proximity";

// Material Kit 2 PRO React routes
import routes from "routes";
import axios from "axios";

let mode = "Production";

if(window.location.hostname === "test.proximityhq.com") {
  // redirect to the equivalent page on test.pakikiproxy.com
  window.location.href = "https://test.pakikiproxy.com" + window.location.pathname + window.location.search;
}

if(window.location.hostname === "proximityhq.com") {
  // redirect to the equivalent page on test.pakikiproxy.com
  window.location.href = "https://pakikiproxy.com" + window.location.pathname + window.location.search;
}

if(window.location.hostname === "www.pakikiproxy.com") {
  // redirect to the equivalent page on pakikiproxy.com
  window.location.href = "https://pakikiproxy.com" + window.location.pathname + window.location.search;
}

if(window.location.hostname === "localhost") {
  axios.defaults.baseURL = 'http://localhost:8080'
  mode = "Development";
}
else if (window.location.hostname === "test.pakikiproxy.com" || window.location.hostname === "test.proximityhq.com") {
  mode = "Development";
}

window.env = mode;

window.paddleInit = false
window.initPaddle = function() {
  if(window.paddleInit) {
    return;
  }
  window.paddleInit = true

  var promise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });

  promise.then(() => {
    if(mode === "Development") {
      window.Paddle.Environment.set('sandbox');
      window.Paddle.Setup({ vendor: 8362 }); 
    }
    else {
      window.Paddle.Setup({ vendor: 155310 });
    }
  })
  .catch((error) => {
    console.log('Script load Paddle payment engine: ', error);
  });
}

function getTitleFromPathname(allRoutes, pathname) {
  for (const route of allRoutes) {
    if (route.collapse) {
      let title = getTitleFromPathname(route.collapse, pathname);
      if (title) {
        return title;
      }
    }

    if (route.route && route.title) {
      let match = matchPath({path: route.route, exact: true, strict: false}, pathname);
      if(match) {
        return route.title;
      }
    }
  }

  return null;
}

export default function App() {
  const { pathname, hash, key } = useLocation();

  // from: https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
  useEffect(() => {

    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    let title = getTitleFromPathname(routes, pathname);
    if(title) {
      document.title = title
      window._paq.push(['setCustomUrl', window.location.href]);  
      window._paq.push(['setDocumentTitle', document.title]);
      window._paq.push(['trackPageView']);
    }
    
    var link_tag = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link_tag.setAttribute('rel', 'canonical');
    var path = window.location.pathname;
    if(path === "/") {
      path = "/pages/landing-pages/pakiki"
    }
    link_tag.setAttribute('href', 'https://pakikiproxy.com' + path);
    document.head.appendChild(link_tag);
    
  }, [pathname, hash, key]); // do this on route change

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    function Loading() {
      return <h2>🌀 Loading...</h2>;
    }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<ProximityPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
