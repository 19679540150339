/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import FavouriteIcon from '@mui/icons-material/Favorite';
import PageViewIcon from '@mui/icons-material/Pageview';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

function AboutUs() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }} id="about_us">
      <Container>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              About Us
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Pākiki is the native New Zealand Māori word meaning <i>"to frequently question, inquire, or probe"</i>.<br/><br/>

              Pākiki Proxy is developed with ❤️ by experienced security professionals.<br/><br/>

              Our mission is to develop next-generation, world-class tools which can be used by the security community
              to help secure their and their client's systems.
              <br/>
            </MKTypography>
            <MKTypography variant="h3" mt={6} mb={1}>
              Contact Us
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              For any questions, or to get in touch, please email <a href="mailto:hello@pakikiproxy.com">hello@pakikiproxy.com</a>.
              <br/>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <MKTypography variant="h3" my={1}>
              Principles
            </MKTypography>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  coloredShadow="success"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <PeopleAltIcon fontSize="small"/>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                    Give back to the security <strong>community</strong>.
                    <br />
                    Lower the barrier to entry for people new to the community.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  coloredShadow="success"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <PageViewIcon fontSize="small"/>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                    <strong>Open source</strong> what we practically can. <br/>Although charge 
                    for professional features in order to fund future development.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="success"
                  color="white"
                  coloredShadow="success"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <FavouriteIcon fontSize="small"/>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                    Take care of our <strong>early supporters</strong>. <br/> While we reserve the
                    right to increase prices for early supporters,<br/> we commit to
                    taking care of anybody supporting us now.
                </MKTypography>
              </MKBox>
              
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;
