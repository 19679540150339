/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The hexToRgb() function helps you to change the hex color code to rgb
  without using the chroma-js library.
 */

function hexToRgb(color) {
  // Remove the "#" symbol from the beginning of the color string if present
  color = color.replace("#", "");

  // Check if the color string is valid (3 or 6 characters in length)
  if (!/^(?:[0-9a-fA-F]{3}){1,2}$/.test(color)) {
    throw new Error("Invalid hexadecimal color format.");
  }

  // Convert shorthand notation (e.g., #abc) to full notation (e.g., #aabbcc)
  if (color.length === 3) {
    color = color
      .split("")
      .map((c) => c + c)
      .join("");
  }

  // Parse the hexadecimal values for red, green, and blue
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);

  // Return the RGB representation as a string
  return `${red}, ${green}, ${blue}`;
}

export default hexToRgb;
