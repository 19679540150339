/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React pages
import Proximity from "pages/LandingPages/Proximity";
import { useNavigate } from 'react-router';

export default function ProximityPage() {
  const navigate = useNavigate();
  return <Proximity navigate={navigate}/>;
}
