/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import React from "react";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import SimpleFooter from "examples/Footers/SimpleFooter";

// Coworking page sections
import Pricing from "pages/LandingPages/Proximity/sections/Pricing";
import AboutUs from "pages/LandingPages/Proximity/sections/AboutUs";
import Places from "pages/LandingPages/Proximity/sections/Places";
import Newsletter from "pages/LandingPages/Proximity/sections/Newsletter";

import Navbar from "pages/LandingPages/Proximity/sections/Navbar";


// Images
import bgImage from "assets/images/bg-pakiki-landing.avif";

class Proximity extends React.Component {
  constructor(props) {
    super(props);

    const account = sessionStorage.getItem('account');
    let accountObj = null;
    try {
      accountObj = JSON.parse(account)
    } catch (err) {
      console.log("Error parsing licence: " + err)
    }

    this.state = {
      account: accountObj
    };
  }

  updateAccount(acc) {
    this.setState({
      account: acc
    });
  }

  render() {
    var backgroundAlpha = CSS.supports("backdrop-filter", "blur(10px)") ? 0.8 : 1.0
    
    return (
      <>
        <Navbar navigate={this.props.navigate} account={this.state.account} updateAccount={(acc) => this.updateAccount(acc)} />
        <MKBox
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              md={7}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Pākiki Proxy
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                mt={1}
                pr={{ md: 12, lg: 24, xl: 32 }}
                opacity={0.8}
              >
                An intercepting proxy designed for security auditing your or your clients' applications quickly and easily.
              </MKTypography>
              <Stack direction="row" spacing={1} mt={6} mb={3}>
                <MKButton variant="gradient" color="success" href="#pricing">
                  get started for free
                </MKButton>
                <MKButton variant="text" color="white" href="#features">
                  read more
                </MKButton>
              </Stack>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, backgroundAlpha),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Places />
          <Pricing />
          <AboutUs />
          <Newsletter />
          
        </Card>
        <MKBox py={3} mb={3}>
          <SimpleFooter />
        </MKBox>
      </>
    );
  }
}

export default Proximity;
