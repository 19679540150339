/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import React, { lazy } from 'react';

// Pages
import ProximityLandingPage from "layouts/pages/landing-pages/proximity";
import Account from "layouts/pages/account";
import Downloads from "layouts/pages/downloads";

// Account
import SignInCoverPage from "layouts/authentication/sign-in/cover";
import RegistrationPage from "layouts/authentication/register";

export const ResetPasswordPage = lazy(() => import(/*webpackChunkName: "accountadmin" */ "layouts/authentication/reset-password/cover"));
export const CustomResetPasswordPage = lazy(() => import(/*webpackChunkName: "accountadmin" */ "layouts/authentication/reset-password"));
export const OrganisationUserSetPasswordPage = lazy(() => import(/*webpackChunkName: "accountadmin" */ "layouts/authentication/org-set-password"));

export const LegalPage = lazy(() => import(/*webpackChunkName: "legal" */ "layouts/pages/legal"));
export const TermsOfServicePage = lazy(() => import(/*webpackChunkName: "legal" */ "layouts/pages/legal/termsofservice"));
export const RefundPage = lazy(() => import(/*webpackChunkName: "legal" */ "layouts/pages/legal/refund"));
export const PrivacyPolicyPage = lazy(() => import(/*webpackChunkName: "legal" */ "layouts/pages/legal/privacy"));

export const Admin = lazy(() => import(/*webpackChunkName: "admin" */ "layouts/pages/admin"));
export const AdminCoupons = lazy(() => import(/*webpackChunkName: "admin" */ "layouts/pages/admin/coupons"));
export const AdminReleases = lazy(() => import(/*webpackChunkName: "admin" */ "layouts/pages/admin/releases"));
export const AdminStats = lazy(() => import(/*webpackChunkName: "admin" */ "layouts/pages/admin/stats"));
export const AdminUsers = lazy(() => import(/*webpackChunkName: "admin" */ "layouts/pages/admin/users"));
export const AdminOrgs = lazy(() => import(/*webpackChunkName: "admin" */ "layouts/pages/admin/organisations"));
export const Release = lazy(() => import(/*webpackChunkName: "admin" */ "layouts/pages/release"));

export const Blog = lazy(() => import(/*webpackChunkName: "blog" */ "layouts/pages/blogs/index"));
export const EditBlogPost = lazy(() => import(/*webpackChunkName: "blog" */ "layouts/pages/blogs/edit"));
export const SingleArticle = lazy(() => import(/*webpackChunkName: "blog" */ "layouts/pages/blogs/single-article"));

let routes = [
  {
    name: "pages",
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "pakiki",
            route: "/pages/landing-pages/pakiki",
            component: <ProximityLandingPage />,
            title: "Pākiki - Intercepting Proxy",
          },
          {
            name: "pakiki",
            route: "/",
            component: <ProximityLandingPage />,
            title: "Pākiki - Intercepting Proxy",
          },
          {
            name: "account",
            route: "/pages/account",
            component: <Account />,
            title: "Account - Pākiki Intercepting Proxy",
          },
          {
            name: "admin",
            route: "/pages/admin",
            component: <Admin />,
            title: "Admin - Pākiki Intercepting Proxy",
          },
          {
            name: "coupons",
            route: "/pages/admin/coupons",
            component: <AdminCoupons />,
            title: "Coupon & Product Admin - Pākiki Intercepting Proxy",
          },
          {
            name: "releases",
            route: "/pages/admin/releases",
            component: <AdminReleases />,
            title: "Release Admin - Pākiki Intercepting Proxy",
          },
          {
            name: "stats",
            route: "/pages/admin/stats",
            component: <AdminStats />,
            title: "Stats - Pākiki Intercepting Proxy",
          },
          {
            name: "users",
            route: "/pages/admin/users",
            component: <AdminUsers />,
            title: "User Admin - Pākiki Intercepting Proxy",
          },
          {
            name: "users",
            route: "/pages/admin/organisations",
            component: <AdminOrgs />,
            title: "Organisation Admin - Pākiki Intercepting Proxy",
          },
          {
            name: "downloads",
            route: "/pages/downloads",
            component: <Downloads />,
            title: "Downloads - Pākiki Intercepting Proxy",
          },
          {
            name: "new release",
            route: "/pages/releases/new",
            component: <Release />,
          },
          {
            name: "edit release",
            route: "/pages/releases/:id/edit",
            component: <Release />,
            title: "Edit Release - Pākiki Intercepting Proxy",
          },
          {
            name: "ToS",
            route: "/pages/legal/termsofservice",
            component: <TermsOfServicePage />,
            title: "Terms of Service - Pākiki Intercepting Proxy",
          },
          {
            name: "Refund Policy",
            route: "/pages/legal/refundpolicy",
            component: <RefundPage />,
            title: "Refund Policy - Pākiki Intercepting Proxy",
          },
          {
            name: "Privacy Policy",
            route: "/pages/legal/privacypolicy",
            component: <PrivacyPolicyPage />,
            title: "Privacy Policy - Pākiki Intercepting Proxy",
          },
          {
            name: "Legal",
            route: "/pages/legal",
            component: <LegalPage />,
            title: "Legal - Pākiki Intercepting Proxy",
          },
          {
            name: "Blog",
            route: "/pages/blog",
            component: <Blog />,
            title: "Pākiki Blog",
          },
          {
            name: "Edit Blog",
            route: "/pages/blog/:id/edit",
            component: <EditBlogPost />,
            title: "Create/Edit Blog Post - Pākiki Intercepting Proxy",
          },
          {
            name: "Blog Page",
            route: "/pages/blog/:id/:title",
            component: <SingleArticle />,
          }
        ],
      },
    ],
  },
  {
    name: "account",
    collapse: [
      {
        name: "sign in",
        dropdown: true,
        collapse: [
          {
            name: "sign in",
            route: "/authentication/sign-in",
            component: <SignInCoverPage />,
            title: "Sign In - Pākiki Intercepting Proxy",
          },
        ],
      },
      {
        name: "reset password",
        dropdown: true,
        collapse: [
          {
            name: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetPasswordPage />,
            title: "Reset Password - Pākiki Intercepting Proxy",
          },
        ],
      },
      {
        name: "reset password",
        dropdown: true,
        collapse: [
          {
            name: "cover",
            route: "/authentication/reset-password",
            component: <CustomResetPasswordPage />,
            title: "Reset Password - Pākiki Intercepting Proxy",
          },
        ],
      },
      {
        name: "reset password",
        dropdown: true,
        collapse: [
          {
            name: "cover",
            route: "/organisation/register_user/:email/:token",
            component: <OrganisationUserSetPasswordPage />,
            title: "Set Password - Pākiki Intercepting Proxy",
          },
        ],
      },
      {
        name: "purchase",
        dropdown: true,
        collapse: [
          {
            name: "cover",
            route: "/pages/purchase",
            component: <RegistrationPage />,
            title: "Purchase - Pākiki Intercepting Proxy",
          },
        ],
      },
    ],
  },
];

export default routes;
