/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import React, { Component } from 'react';

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
//import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import bgImage from "assets/images/bg-pakiki-landing.avif";

const axios = require('axios').default;

class SignInCover extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      rememberMe: false,
      email: '',
      password: '',
      error: '',
      submitEnabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.getAccountDetails = this.getAccountDetails.bind(this);
  }

  getAccountDetails() {
    axios.get('/api//accounts/details', { withCredentials: true })
      .then(function (response) {
        // handle success
        console.log(response);
      });
  }

  handleSubmit(e) {
    let vm = this;
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    const FormData = require('form-data');
 
    const form = new FormData();

    Object.entries(this.state).forEach(entry => {
      const [key, value] = entry;
      form.append(key, value)
    });
    let navigate = vm.props.navigate;

    this.setState({
      submitEnabled: false
    });

    axios.post('/api/accounts/login', form, { withCredentials: true })
      .then(function (response) {
        // handle success
        //console.log(navigation);
        console.log(response);

        window.sessionStorage.setItem('account', JSON.stringify(response.data));
        navigate('/pages/account')

        /*axios.get('/accounts/details', { withCredentials: true })
        .then(function (response) {
          // handle success
          console.log(response);
        });*/

      })
      .catch((error) => {
        // handle error
        console.log("Error:");
        console.log(error);
        vm.setState({
          'error': error.response.data
        });
        //console.log(this.state)
      })
      .then(function () {
        vm.setState({
          submitEnabled: true
        });
      });

    e.preventDefault()
    return false
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <CoverLayout image={bgImage} navigate={this.props.navigate} >
        <Card>
          <MKBox
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MKTypography>
            <MKTypography display="block" variant="button" color="white" my={1}>
              Enter your email and password to Sign In
            </MKTypography>
          </MKBox>
          <MKBox pt={4} pb={3} px={3}>
            <form onSubmit={this.handleSubmit}>
              <MKBox mb={2}>
                <MKInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  placeholder=""
                  name="email"
                  onChange={this.handleChange} 
                  InputLabelProps={{ shrink: true }}
                  value={this.state.email}
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  name="password"
                  onChange={this.handleChange} 
                  InputLabelProps={{ shrink: true }}
                  value={this.state.password}
                />
              </MKBox>
              <MKBox mt={3} mb={1} textAlign="center">
                <MKTypography variant="button" color="error" fontWeight="bold">
                  {this.state.error}
                </MKTypography>
              </MKBox>
              <MKBox mt={4} mb={1}>
                <MKButton variant="gradient" color="success" fullWidth type="submit" disabled={!this.state.submitEnabled}>
                  sign in
                </MKButton>
              </MKBox>
              <MKBox mt={3} mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MKTypography
                    component={Link}
                    to="/pages/purchase"
                    variant="button"
                    color="success"
                    fontWeight="medium"
                    textGradient
                  >
                    Buy Pākiki
                  </MKTypography>
                </MKTypography>
              </MKBox>
              <MKBox mt={0} mb={1} textAlign="center">
                <MKTypography
                      component={Link}
                      to="/authentication/reset-password"
                      variant="button"
                      color="success"
                      fontWeight="medium"
                      textGradient
                    >
                      Forgot Password?
                    </MKTypography>
              </MKBox>
            </form>
          </MKBox>
        </Card>
        <MKBox mt={5}>
        </MKBox>
      </CoverLayout>
    );
  }
}

export default SignInCover;
