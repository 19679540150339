// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import React from "react";

const axios = require('axios').default;

class Newsletter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
      submitEnabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  
  handleChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  onSubmit(e) {
    let vm = this;
    const form = new FormData();
    form.append('email', this.state.email);

    let url = '/api/newsletter_registration'

    vm.setState({
      submitEnabled: false
    });
    
    axios.post(url, form, { withCredentials: true })
      .then(function (response) {
        // success case
        vm.setState({
          email: '',
          error: response.data
        });

      })
      .catch((error) => {
        vm.setState({
          error: error.response.data
        });
      })
      .then(() => {
        vm.setState({
          submitEnabled: true
        });
      });

    e.preventDefault();
    return false;
  }

  render () {
    return (
      <MKBox component="section" py={3} id="newsletter">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5} mr="auto">
              <MKTypography variant="h4" mb={1} sx={{'font-family': '"Space Grotesk",sans-serif'}}>
                Get Notified About Updates
              </MKTypography>
              <MKTypography variant="body2" color="text">
                Sign up for our newsletter and get updates on new features, releases, etc.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={6} mt={1} flexDirection="column" justifyContent="center" ml="auto">
              <MKBox component="form" method="" action="" onSubmit={this.onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <MKInput name="email" label="Your Email..." fullWidth onChange={this.handleChange} aria-label="Email address" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MKButton type="submit" variant="gradient" color="success" fullWidth sx={{ height: "100%" }} disabled={!this.state.submitEnabled}>
                      Subscribe
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
              <MKBox mt={2}>
                <MKTypography variant="body2" color="error">
                  {this.state.error}
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
}

export default Newsletter;