/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import FeatureBlogCard from "examples/Cards/BlogCards/FeatureBlogCard";

// 1128x714

import requestRenderImgLinux from "assets/images/screenshots/request-render-trimmed-linux.avif";
import requestRenderImgMacOS from "assets/images/screenshots/request-render-trimmed-macos.avif";
import requestRenderFullImgLinux from "assets/images/screenshots/request-render-full-linux.avif";
import requestRenderFullImgMacOS from "assets/images/screenshots/request-render-full-macos.avif";
import requestRenderFullImgWeb from "assets/images/screenshots/request-render-full-web.avif";
import oobImgLinux from "assets/images/screenshots/oob-trimmed-linux.avif";
import oobImgMacOS from "assets/images/screenshots/oob-trimmed-macos.avif";
import oobFullImgLinux from "assets/images/screenshots/oob-full-linux.avif";
import oobFullImgMacOS from "assets/images/screenshots/oob-full-macos.avif";
import oobFullImgWeb from "assets/images/screenshots/oob-full-web.avif";
import fuzzingImgLinux from "assets/images/screenshots/fuzzing-trimmed-linux.avif";
import fuzzingImgMacOS from "assets/images/screenshots/fuzzing-trimmed-macos.avif";
import fuzzingFullImgLinux from "assets/images/screenshots/fuzzing-full-linux.avif";
import fuzzingFullImgMacOS from "assets/images/screenshots/fuzzing-full-macos.avif";
import fuzzingFullImgWeb from "assets/images/screenshots/fuzzing-full-web.avif";
import webImg from "assets/images/screenshots/web-trimmed.avif";
import webFullImg from "assets/images/screenshots/web-full.avif";
import automationImgLinux from "assets/images/screenshots/automation-trimmed-linux.avif";
import automationImgMacOS from "assets/images/screenshots/automation-trimmed-macos.avif";
import automationFullImgLinux from "assets/images/screenshots/automation-full-linux.avif";
import automationFullImgMacOS from "assets/images/screenshots/automation-full-macos.avif";
import scriptImgLinux from "assets/images/screenshots/script-trimmed-linux.avif";
import scriptImgMacOS from "assets/images/screenshots/script-trimmed-macos.avif";
import scriptFullImgLinux from "assets/images/screenshots/script-full-linux.avif";
import scriptFullImgMacOS from "assets/images/screenshots/script-full-macos.avif";

function Places() {
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/coworking",
    color: "dark",
    label: "view",
  };

  return (
    <MKBox component="section" py={12} id="features">
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            Explore the features{" "}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <FeatureBlogCard
                imageLinux={requestRenderImgLinux}
                imageMacOS={requestRenderImgMacOS}
                title="Intercept"
                description="Intercept HTTPS requests and use those as the basis for further manual requests or fuzzing. Modify requests before they're sent/received."
                fullImageLinux={requestRenderFullImgLinux}
                fullImageMacOS={requestRenderFullImgMacOS}
                fullImageWeb={requestRenderFullImgWeb}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <FeatureBlogCard
                imageLinux={oobImgLinux}
                imageMacOS={oobImgMacOS}
                fullImageLinux={oobFullImgLinux}
                fullImageMacOS={oobFullImgMacOS}
                fullImageWeb={oobFullImgWeb}
                title="Protocols"
                description="In addition to supporting TLS interception, you can also intercept websocket connections, and view out of band interactions."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <FeatureBlogCard
                imageLinux={fuzzingImgLinux}
                imageMacOS={fuzzingImgMacOS}
                fullImageLinux={fuzzingFullImgLinux}
                fullImageMacOS={fuzzingFullImgMacOS}
                fullImageWeb={fuzzingFullImgWeb}
                title="Payload Fuzzing"
                description="Insert payloads into requests in order to find direct object reference bugs, brute force, or fuzz for injection flaws."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <FeatureBlogCard
                imageLinux={webImg}
                fullImageWeb={webFullImg}
                title="Native/Web Interfaces"
                description="Contains a native user interface for Kali/Linux and MacOS. On Windows and for forward deployments it contains a web frontend."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <FeatureBlogCard
                imageLinux={automationImgLinux}
                imageMacOS={automationImgMacOS}
                fullImageLinux={automationFullImgLinux}
                fullImageMacOS={automationFullImgMacOS}
                title="Advanced Automation (Paid only)"
                description="Built-in scripts to automate the boring stuff and help find vulnerabilities faster. These cover many common tasks and bug classes."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <FeatureBlogCard
                imageLinux={scriptImgLinux}
                imageMacOS={scriptImgMacOS}
                fullImageLinux={scriptFullImgLinux}
                fullImageMacOS={scriptFullImgMacOS}
                title="Scripting (Paid only)"
                description="Develop your own scripts for your own advanced exploitation. Write in Python using an API to interact with the proxy."
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
