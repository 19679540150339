/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Card from "@mui/material/Card";


import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKTypography from "components/MKTypography";

import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 PRO React examples
import Navbar from "pages/LandingPages/Proximity/sections/Navbar";
import Editions from "pages/Downloads/sections/Editions";
import React from "react";

// Images
import bgImage from "assets/images/bg-pakiki-landing.avif";

class Downloads extends React.Component {
  constructor(props) {
    super(props);

    const account = sessionStorage.getItem('account');
    let accountObj = null;
    try {
      accountObj = JSON.parse(account)
    } catch (err) {
      console.log("Error parsing licence: " + err)
    }

    this.state = {
      account: accountObj
    };
  }

  updateAccount(acc) {
    this.setState({
      account: acc
    });
  }

  render() {
    var backgroundAlpha = CSS.supports("backdrop-filter", "blur(10px)") ? 0.8 : 1.0

    return (
      <>
        <Navbar navigate={this.props.navigate} account={this.state.account} updateAccount={(acc) => this.updateAccount(acc)} />
        
        <MKBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h2"
                color="white"
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Downloads
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, backgroundAlpha),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Editions />
        </Card>
        <MKBox py={3} mb={3}>
          <SimpleFooter />
        </MKBox>
      </>
    );
  }
}

export default Downloads;
