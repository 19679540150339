/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import React, { Component } from 'react';

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormLabel, FormControlLabel, Radio, RadioGroup } from "@mui/material";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-pakiki-landing.avif";

import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';

const axios = require('axios').default;

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    
    const searchParams = new URLSearchParams(document.location.search);
    var edition = searchParams.get("edition");
    if(edition != 'Personal' && edition != 'Professional') {
      edition = '';
    }

    this.state = {
      name: '',
      organisation: '',
      email: '',
      password: '',
      password_confirmation: '',
      coupon: '',
      errors: '',
      edition: edition,
      buy_now: true,
      permission_term: false,
      activeTab: 0,
      personal: true,

      submitEnabled: true,
      licence_count: 1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePermissionTerm = this.togglePermissionTerm.bind(this);
  }

  componentDidMount() {
    window.initPaddle();
  }

  setVal(name, value) {
    this.setState({
      [name]: value
    });
  }

  onPaymentFinish(success) {
    // refresh the account details, then navigate
    let vm = this;

    axios.get('/api/accounts/details', { withCredentials: true })
        .then(function (response) {
            // handle success
            console.log(response);
            let accountObj = JSON.stringify(response.data);
            window.sessionStorage.setItem('account', accountObj);
            let navigate = vm.props.navigate;
            var uri = '/pages/account';
            if(success) {
              uri += "?processing_payment=true"
            }
            navigate(uri);
        })
        .catch((error) => {
            console.log(error);
            if(error.response.status === 401) {
                window.sessionStorage.setItem('account', '{"state": "unauthenticated"}')
                vm.updateAccount({state: 'unauthenticated'});
            }
        })
  }

  handleSubmit(e) {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    const FormData = require('form-data');
 
    const form = new FormData();

    Object.entries(this.state).forEach(entry => {
      const [key, value] = entry;
      form.append(key, value)
    });

    this.setState({
      submitEnabled: false
    });

    let vm = this;
    
    axios.post('/api/accounts/register', form, { withCredentials: true })
      .then(function (response) {
        vm.setVal('errors', '')

        var user = response.data.User
        window.sessionStorage.setItem('account', user)
        
        var product_id = Number(response.data.ProductID)
        
        let checkout_params = {
          product: product_id,
          email: user.Email,
          successCallback: () => { vm.onPaymentFinish(true); },
          closeCallback: () => { vm.onPaymentFinish(false); },
          passthrough: '{"user_id": "' + user.ID + '"}',
          allowQuantity: false,
          disableLogout: true,
        }

        if(vm.state.activeTab === 1) {
          checkout_params['quantity'] = vm.state.licence_count;
        }

        window.Paddle.Checkout.open(checkout_params);
      })
      .catch((error) => {
        // handle error
        console.log(error)
        this.setVal('errors', error.response.data)
      })
      .then(function () {
        // always executed
        vm.setState({
          submitEnabled: true
        });
      });

    e.preventDefault()
    return false
  }

  handleTabType() {
    let newState = 0;
    if(this.state.activeTab === 0) {
      newState = 1;
    }

    this.setState({
      activeTab: newState,
      personal: (newState === 0)
    });
  }
  
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  togglePermissionTerm() {
    this.setState({
      permission_term: !this.state.permission_term
    });
  }

  personalPersonalPricing() {
    if(this.state.coupon !== '') {
      return "Personal — Price determined in the next step"
    } else {
      return "Personal — $99 (USD) per year"
    }
  }

  personalProPricing() {
    if(this.state.coupon !== '') {
      return "Professional — Price determined in the next step"
    } else {
      return "Professional — $399 (USD) per year"
    }
  }

  orgPricing() {
    if(this.state.activeTab === 0 || this.state.licence_count === 1 || this.state.licence_count <= 0) {
      if(this.state.coupon !== '') {
        return "Price determined based on your coupon in the next step"
      } else {
        return "$399 (USD) for one year"
      }
    }
    else {
      return "" + this.state.licence_count + " licences at $399 each for one year.\nTotal: $" + (this.state.licence_count * 399) + " (USD)"
    }
  }

  render() {
    let errors;
    if (this.state.errors !== '') {
      errors = <MKBox textAlign="center" mt={3} mb={1}><MKTypography display="block" variant="button" color="error" fontWeight="bold" my={1}>{this.state.errors}</MKTypography></MKBox>;
    }

    var orgBlurb = <></>
    if(this.state.activeTab === 1) {
      orgBlurb = <MKBox mb={4}>
        <MKTypography variant="body2" color="text">
          <i>For purchasing and managing multiple licences for people within your organisation.</i>
        </MKTypography>
      </MKBox>
    }

    return (
      <CoverLayout image={bgImage} navigate={this.props.navigate}>
        <Card>
          <MKBox
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Buy Pākiki
            </MKTypography>
            <MKTypography display="block" variant="button" color="white" my={1}>
              Please provide your details in order to create an account.
            </MKTypography>
          </MKBox>
          <MKBox textAlign="center" xs={11} mx={2} mt={3} mb={1}>
            <AppBar position="static">
              <Tabs value={this.state.activeTab} onChange={() => this.handleTabType()}>
                <Tab label="Personal" icon={<PersonIcon></PersonIcon>} />
                <Tab label="Organisation" icon={<DashboardIcon></DashboardIcon>} />
              </Tabs>
            </AppBar>
          </MKBox>
          <MKBox p={3}>
            <form onSubmit={this.handleSubmit}>
              {orgBlurb}
              <MKBox mb={2}>
                <MKTypography variant="h5" fontWeight="medium">
                  Your details
                </MKTypography>
              </MKBox>
              <MKBox mb={2}>
                <MKInput value={this.state.name} onChange={this.handleChange} type="text" label="Full Name" name="name" fullWidth />
              </MKBox>
              <MKBox mb={2}>
                <MKInput value={this.state.email} onChange={this.handleChange} type="email" label="Email" name="email" fullWidth />
              </MKBox>
              <MKBox mb={2}>
                <MKInput value={this.state.password} onChange={this.handleChange} type="password" label="Password" name="password" fullWidth />
              </MKBox>
              <MKBox mb={2}>
                <MKInput value={this.state.password_confirmation} onChange={this.handleChange} type="password" label="Password Confirmation" name="password_confirmation" fullWidth />
              </MKBox>

              {
                this.state.activeTab === 0 ? 
                <MKBox mb={2}>
                  <MKInput value={this.state.coupon} onChange={this.handleChange} type="text" label="Coupon" name="coupon" fullWidth />
                </MKBox>
                :
                <div>
                  <MKBox mb={2}>
                  <MKTypography variant="h5" fontWeight="medium">
                    Organisation Details
                  </MKTypography>
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput value={this.state.organisation} onChange={this.handleChange} type="text" label="Name" name="organisation" fullWidth />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput value={this.state.licence_count} onChange={this.handleChange} type="number" label="Licenses to purchase" name="licence_count" fullWidth />
                  </MKBox>
                </div>
              }

              {
                this.state.activeTab === 0 ? 
                  <MKBox ml={0} mt={3}>
                    <MKTypography variant="h5" fontWeight="medium">
                      Edition
                    </MKTypography>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="edition"
                      onChange={this.handleChange}
                      value={this.state.edition}
                      >
                        <FormControlLabel value="Professional" control={<Radio />} label={this.personalProPricing()} />
                        <FormControlLabel value="Personal" control={<Radio />} label={this.personalPersonalPricing()} />
                    </RadioGroup>
                  </MKBox>
                :
                <MKBox display="flex" ml={0}>
                  <MKTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                  >
                    {this.orgPricing()}
                  </MKTypography>
                </MKBox>
              }
              
              <MKBox display="flex" ml={0} mt={2}>
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  By registering you agree to the <MKTypography
                    component={Link}
                    to="/pages/legal/termsofservice"
                    variant="button"
                    color="success"
                    fontWeight="medium"
                    textGradient
                  >Terms of Service</MKTypography>
                </MKTypography>
              </MKBox>

              <MKBox mt={3} mb={1}>
                <MKButton variant="gradient" color="success" type="submit" fullWidth disabled={!this.state.submitEnabled}>
                  Payment
                </MKButton>
              </MKBox>
              {errors}
              <MKBox mt={3} mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MKTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="success"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </form>
          </MKBox>
        </Card>
        <MKBox mt={5}>
        </MKBox>
      </CoverLayout>
    );
  }
}

export default RegistrationForm;
