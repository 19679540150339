/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import Navbar from "pages/LandingPages/Proximity/sections/Navbar";
import SimpleFooter from "examples/Footers/SimpleFooter";

// HelpCenter page sections
import Downloads from "pages/Account/sections/Downloads";
import Licencing from "pages/Account/sections/Licencing";
import Payment from "pages/Account/sections/Payment";
import UserManagement from "pages/Account/sections/UserManagement";

import React from "react";

// Images
import bgImage from "assets/images/bg-pakiki-landing.avif";

const axios = require('axios').default;

class Account extends React.Component {
  constructor(props) {
    super(props);

    const account = sessionStorage.getItem('account');
    let accountObj = null;
    try {
      accountObj = JSON.parse(account)
    } catch (err) {
      console.log("Error parsing licence: " + err)
    }

    let processingPayment = false;
    if(this.props.location.search.indexOf("processing_payment") !== -1) {
      let paymentDetailsExistFunc = (account) => {
        if(account.Licence !== null && account.Licence.Expiry !== null) {
          // check if the date is more than 30 years into the future
          let expiryDate = new Date(account.Licence.Expiry);
          let now = new Date();
          let diff = expiryDate.getTime() - now.getTime();
          let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          if(diffDays > 365 * 30) {
            return true;
          }
        }
        return account.PaymentDetails !== null;
      }
  
      processingPayment = !paymentDetailsExistFunc(accountObj);
    }

    this.state = {
      account: accountObj,
      processingPayment: processingPayment,
    };

    if(accountObj === null) {
      this.updateAccountFromServer ();
    }

    if(processingPayment) {
      this.awaitPayment((account) => {
        if(account.Licence !== null && account.Licence.Expiry !== null) {
          // check if the date is more than 30 years into the future
          let expiryDate = new Date(account.Licence.Expiry);
          let now = new Date();
          let diff = expiryDate.getTime() - now.getTime();
          let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          if(diffDays > 365 * 30) {
            return true;
          }
        }
        
        return account.PaymentDetails != null;
      });
    }
  }

  awaitPayment(func) {
    let vm = this;

    if(func(this.state.account)) {
      return;
    }

    vm.setState({
      processingPayment: true,
    });

    // Run every 5000 milliseconds
    const timer = setInterval(() => {
      this.updateAccountFromServer(() => {
        if(func(this.state.account)) {
          vm.setState({
            processingPayment: false,
          });
          clearInterval(timer);
        }
      })
    }, 5000);
  }

  updateAccountFromServer(onSuccessFunc) {
    let vm = this;
    axios.get('/api/accounts/details', { withCredentials: true })
        .then(function (response) {
            // handle success
            console.log(response);
            let accountObj = JSON.stringify(response.data);
            window.sessionStorage.setItem('account', accountObj);
            vm.updateAccount(response.data);
            if(onSuccessFunc !== undefined) {
              onSuccessFunc();
            }
        })
        .catch((error) => {
            console.log(error);
            if(error.response.status === 401) {
                window.sessionStorage.setItem('account', '{"state": "unauthenticated"}')
                vm.updateAccount({state: 'unauthenticated'});
            }
        })
  }

  updateAccount(acc) {
    if('state' in acc && acc['state'] === 'unauthenticated') {
      this.props.navigate('/authentication/sign-in');
    };

    this.setState({
      account: acc
    });
  }

  render() {
    if (this.state.account === null) {
      return <></>
    }

    let paymentProcessing = '';
    if(this.state.processingPayment) {
      paymentProcessing = <Container sx={{position: "fixed", top: 100, zIndex: 1 }}>
          <MKAlert color="warning">Processing payment, this page will be updated once your payment has been processed.</MKAlert>
        </Container>
    }

    var backgroundAlpha = CSS.supports("backdrop-filter", "blur(10px)") ? 0.8 : 1.0

    var downloads = <MKBox component="section" pt={9} id="downloads"></MKBox>
    if(this.state.account.Licence !== null && this.state.account.Licence.Expiry !== null) {
      // also check that we're not past expiry
      let expiryDate = new Date(this.state.account.Licence.Expiry);
      let now = new Date();
      let diff = expiryDate.getTime() - now.getTime();
      let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if(diffDays > 0) {
        downloads = <Downloads />
      }
    }

    return (
      <>
        <Navbar light navigate={this.props.navigate} account={this.state.account} updateAccount={(acc) => this.updateAccount(acc)} />
        <MKBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          {paymentProcessing}

          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h2"
                color="white"
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Your account
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, backgroundAlpha),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: "hidden",
          }}
        >
          {downloads}

          <Licencing
            account={this.state.account}
            updateAccount={(acc) => this.updateAccount(acc)}
            updateAccountFromServer={() => this.updateAccountFromServer()}
            awaitPaymentFunc={(func) => this.awaitPayment(func)}
          />

          <Payment
            paymentDetails={this.state.account.PaymentDetails}
            account={this.state.account}
            awaitPaymentFunc={(func) => this.awaitPayment(func)}
          />
          
          <UserManagement
            account={this.state.account}
            updateAccount={(acc) => this.updateAccount(acc)}
          />
          
          <MKBox component="section">
            <Container>
              <Grid container item xs={12} lg={11} mx="auto">
                <MKTypography variant="h3" textGradient py={2} xs={12} lg={11}>
                  Support
                </MKTypography>
              </Grid>

              <Grid container item xs={12} lg={11} mx="auto">
                <MKTypography variant="body2" xs={12}  lg={11} mb={2}>
                  For any account related problems or to perform actions not possible through this portal, contact <Link href="mailto:accounts@pakikiproxy.com">accounts@pakikiproxy.com</Link>.<br/>
                  For product support, contact <Link href="mailto:support@pakikiproxy.com">support@pakikiproxy.com</Link>.
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
          
        </Card>
        <MKBox py={3} mb={3}>
          <SimpleFooter />
        </MKBox>
      </>
    )
  }
}

export default Account;
