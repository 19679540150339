/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// HelpCenter page components
import React from "react";
import { FormLabel, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const axios = require('axios').default;

function dateStr(dte) {
  var lastUsedDate = new Date(Date.parse(dte))
  var lastUsedStr = ''
  if(lastUsedDate.getTime() / 1000 !== 0) {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    lastUsedStr = lastUsedDate.getDate().toString() + ' ' + months[lastUsedDate.getMonth()] + ' ' + lastUsedDate.getFullYear().toString();
  }

  return lastUsedStr;
}  

class Payment extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        renewQty: '',
        buyLicenceCount: '',
        buyLicenceEnabled: true,
        showSpinner: false,
        products: [],
        productID: '',
      }

      this.doNotRenewLicences = this.doNotRenewLicences.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handlePurchaseLicences = this.handlePurchaseLicences.bind(this);
      this.onBuySubscription = this.onBuySubscription.bind(this);
      this.onPaymentSuccess = this.onPaymentSuccess.bind(this);

      this.fetchProducts();
    }

    componentDidMount() {
      window.initPaddle();
    }

    doNotRenewLicences(event) {
      let vm = this;
      let prevQty = vm.props.paymentDetails.Quantity;

      vm.setState({
        showSpinner: true
      });

      axios.post("/api/organisation/remove_unused_licences", {}, { withCredentials: true })
        .then(function () {
          vm.props.awaitPaymentFunc((account) => {
            return account.PaymentDetails.Quantity !== prevQty;
          });
        })
        .catch((error) => {
          // handle error
          console.log(error)
        })
        .then(() => {
          vm.setState({
            showSpinner: false,
          });
        })
  
      event.preventDefault();
        
      return false;
    }
  
    handleChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
    
      this.setState({
        [name]: value
      });
    }

    fetchProducts() {
      let vm = this;
      axios.get('/api/products', { withCredentials: true })
        .then(function (response) {
          var products = response.data;
          var productID = '';
          // iterate through all of the products
          for (var i = 0; i < products.length; i++) {
            if(products[i]['Edition'] === 'Personal') {
              products[i]['Edition'] = 'Personal — $99 (USD) per year'
            }
            else if(products[i]['Edition'] === 'Professional') {
              products[i]['Edition'] = 'Professional — $399 (USD) per year'
              productID = products[i]['ProductID'];
            }
          }

          vm.setState({
            products: products,
            productID: productID,
          });
        })
        .catch((error) => {
          // handle error
          vm.setState({
            purchaseLicenceError: error.response.data
          });
        })
    }

    handlePurchaseLicences(event) {
      event.preventDefault();
      let vm = this;

      let prevQty = vm.props.paymentDetails.Quantity;
  
      const form = new FormData();
      form.append('qty', this.state.buyLicenceCount);
  
      this.setState({
          buyLicenceEnabled: false,
          showSpinner: true,
      });
  
      axios.post('/api/organisation/purchase_licences', form, { withCredentials: true })
        .then(function () {
          vm.setState({
            purchaseLicenceError: '',
            buyLicenceCount: '',
          });

          vm.props.awaitPaymentFunc((account) => {
            return account.PaymentDetails.Quantity !== prevQty;
          });

        })
        .catch((error) => {
          // handle error
          vm.setState({
            purchaseLicenceError: error.response.data
          });
        })
        .then(function () {
          vm.setState({
              buyLicenceEnabled: true,
              showSpinner: false,
          });    
        });
  
      event.preventDefault();
      return false
    }

    isOrganisation() {
      return this.props.account && 'Organisation' in this.props.account && this.props.account.Organisation != null
    }
  
    onBuySubscription(e) {
        e.preventDefault();

        let checkout_params = {
            product: this.state.productID,
            email: this.props.account.Email,
            successCallback: () => { this.onPaymentSuccess(); }, // I think this should call something above which displays a banner saying that payment is benig processed (which refreshes periodically - maybe every 5 seconds)
            passthrough: '{"user_id": "' + this.props.account.ID + '"}',
            allowQuantity: false,
            disableLogout: true,
          }
    
          if(this.isOrganisation()) {
            checkout_params['quantity'] = parseInt(this.state.renewQty, 10);
          }
    
          window.Paddle.Checkout.open(checkout_params);
    
        return false;
    }

    onPaymentSuccess() {
      this.props.awaitPaymentFunc((account) => {
        return account.PaymentDetails !== null;
      });
    }

    render() {
      if(this.isOrganisation() && this.props.account.OrganisationAdmin === false) {
        return <span></span>
      }

      let nextPayment = '';

      let spinner = ''
      if(this.state.showSpinner) {
        spinner = <CircularProgress color="primary" size="1rem" />
      }
      
      if('account' in this.props && 'Licence' in this.props.account && this.props.account.Licence != null && this.props.account.Licence.Lifetime) {
        nextPayment = <div>You have a lifetime {} licence. For changes to your account, please contact <Link href="mailto:accounts@pakikiproxy.com">accounts@pakikiproxy.com</Link></div>
      }
      else if('paymentDetails' in this.props && this.props.paymentDetails != null) {
        let pd = this.props.paymentDetails;
        let qty = '';
        let doNotRenewLink = '';

        if(this.isOrganisation()) {
          if(this.props.paymentDetails.Quantity === 1) {
            qty = "Your subscription is for 1 licence.";
          }
          else {
            qty = "Your subscription is for " + this.props.paymentDetails.Quantity + " licences. "
          }

          let users = this.props.account.Organisation.Users;

          let assignedLicCount = 0;
          users.forEach((u) => {
            if(u.Licence != null) {
              assignedLicCount++;
            }
          });

          doNotRenewLink = <span><Link
              href="#"
              onClick={this.doNotRenewLicences}
              color="primary"
            >
              Do not renew excess licences
            </Link>, </span>

          if(assignedLicCount >= this.props.paymentDetails.Quantity) {
            doNotRenewLink = '';
          }
        }

        nextPayment = <div>Your subscription will automatically renew on: {dateStr(pd.RenewalDate)}. {qty}<MKTypography variant="body2" color="text">{doNotRenewLink}<Link color="primary" href={pd.UpdateURL} rel="noreferrer" target="_blank">Update payment details</Link> or <Link color="primary" href={pd.CancelURL} rel="noreferrer" target="_blank">Cancel subscription</Link>&nbsp;&nbsp;{spinner}</MKTypography></div>
      } else {
        let quantity = ''

        let product_selection = <></>

        if(this.isOrganisation()) {
            quantity = <span><MKInput label="Quantity" name="renewQty" onChange={this.handleChange} size="small" />&nbsp;&nbsp;</span>
        } else {
          let products = []
          this.state.products.forEach((p) => {
            products.push(<FormControlLabel value={p['ProductID']} control={<Radio />} label={p['Edition']}></FormControlLabel>)
          });
          
          product_selection = <><RadioGroup aria-labelledby="demo-radio-buttons-group-label" 
              name="productID"
              onChange={this.handleChange}
              value={this.state.productID}>{products}</RadioGroup><br/></>
        }

        nextPayment = <MKTypography xs={12} variant="body2">
          You do not have an active subscription.
          &nbsp;&nbsp;
          <MKBox component="form" method="" action="" onSubmit={this.onBuySubscription} py={2}>
            {quantity}
            {product_selection}
            <MKButton color="success" variant="gradient" sx={{height: "100%"}} type="submit">Buy Now</MKButton>
          </MKBox>
        </MKTypography>
      }

      let purchaseNewLicences = <Grid container item xs={12} lg={11} mx="auto">
        <MKBox component="form" method="" action="" onSubmit={this.handlePurchaseLicences}>
          <MKTypography py={2} xs={12} variant="body2" >
            Purchase <MKInput label="Count" name="buyLicenceCount" onChange={this.handleChange} size="small" value={this.state.buyLicenceCount} /> new licences
            &nbsp;&nbsp;
            <MKButton type="submit" variant="gradient" color="success" sx={{ height: "100%" }} disabled={!this.state.buyLicenceEnabled}>
              Buy now
            </MKButton>
            &nbsp;&nbsp;
          </MKTypography>
        </MKBox>
      </Grid>

      if(!this.isOrganisation() || this.props.account.PaymentDetails === null) {
        purchaseNewLicences = '';
      }
      
      return (
        <MKBox component="section" id="payment">
          <Container>
            <Grid container item xs={12} lg={11} mx="auto">
              <MKTypography variant="h4" textGradient py={2} xs={12} lg={11}>
                Subscription
              </MKTypography>
            </Grid>
            
            <Grid container item xs={12} lg={11} mx="auto">
              <MKTypography variant="body2" mb={2} xs={12} lg={11}>
                {nextPayment}
              </MKTypography>
            </Grid>

            {purchaseNewLicences}

            <Grid item xs={12}>
              <MKBox mb={1}>
                  <MKTypography color="error">
                    {this.state.error}
                  </MKTypography>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      );
    }
}

export default Payment;
