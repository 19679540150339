/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { Link as RouterLink } from 'react-router-dom';

import InstallInstructions from "pages/Account/sections/InstallInstructions";

import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React from "react";
const axios = require('axios').default;

class Editions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      communityDropdown: null,
      proDropdown: null,
      personalDropdown: null,
      communityVersion: '',
      proVersion: '',
      communityLinks: [],
      proLinks: [],
      showProfessional: false,
    }

    this.openCommunityDropdown = this.openCommunityDropdown.bind(this);
    this.closeCommunityDropdown = this.closeCommunityDropdown.bind(this);
    this.openProDropdown = this.openProDropdown.bind(this);
    this.closeProDropdown = this.closeProDropdown.bind(this);
    this.openPersonalDropdown = this.openPersonalDropdown.bind(this);
    this.closePersonalDropdown = this.closePersonalDropdown.bind(this);

    this.fetchReleases()
  }

  fetchReleases() {
    let vm = this;
    axios.get("/api/versions/latest", { withCredentials: true })
      .then(function (response) {
        response.data.forEach((r) => {
          var links = []
          try {
            links = JSON.parse(r.Downloads)
          } catch (e) {
            console.log("Could not parse download links: " + e)
          }

          if (r.Edition === "Community") {
            vm.setState({
              communityVersion: r.Version,
              communityLinks: links
            })
          } else {
            vm.setState({
              proVersion: r.Version,
              proLinks: links
            })
          }
        });
      })
      .catch((error) => {
        // handle error
        console.log(error)
      })
  }

  openCommunityDropdown(e) {
    this.setState({
      communityDropdown: e.target
    });
  }

  openProDropdown(e) {
    this.setState({
      proDropdown: e.target
    });
  }

  openPersonalDropdown(e) {
    this.setState({
      personalDropdown: e.target
    });
  }

  closeCommunityDropdown() {
    this.setState({
      communityDropdown: null
    });
  }

  closeProDropdown() {
    this.setState({
      proDropdown: null
    });
  }

  closePersonalDropdown() {
    this.setState({
      personalDropdown: null
    });
  }

  render() {
    // Styles
    const iconStyles = {
      ml: 1,
      fontWeight: "bold",
      transition: "transform 200ms ease-in-out",
    };

    const communityDropdownIconStyles = {
      transform: this.state.communityDropdown ? "rotate(180deg)" : "rotate(0)",
      ...iconStyles,
    };

    const personalDropdownIconStyles = {
      transform: this.state.proDropdown ? "rotate(180deg)" : "rotate(0)",
      ...iconStyles,
    };

    const proDropdownIconStyles = {
      transform: this.state.proDropdown ? "rotate(180deg)" : "rotate(0)",
      ...iconStyles,
    };

    let downloadOrBuyPersonal = (<div></div>)
    let downloadOrBuyPro = (<div></div>)
    if (this.state.proLinks.length === 0) {
      downloadOrBuyPro = (<Container>
        <MKTypography variant="h1">
          <MKBox component="small">$</MKBox>399
          <MKTypography display="inline" component="small" variant="h5" color="inherit">
            USD/yr
          </MKTypography>
        </MKTypography>

        <Link to={"/pages/purchase?edition=Professional"} component={RouterLink}>
          <MKButton variant="gradient" color="success" size="large" sx={{ my: 2 }}>
            Buy Now
          </MKButton>
        </Link>
      </Container>)

      downloadOrBuyPersonal = (<Container>
        <MKTypography variant="h1">
          <MKBox component="small">$</MKBox>99
          <MKTypography display="inline" component="small" variant="h5" color="inherit">
            USD/yr
          </MKTypography>
        </MKTypography>

        <Link to={"/pages/purchase?edition=Personal"} component={RouterLink}>
          <MKButton variant="gradient" color="success" size="large" sx={{ my: 2 }}>
            Buy Now
          </MKButton>
        </Link>
      </Container>)
    } else {
      downloadOrBuyPro = (<Container>
        <MKButton variant="gradient" color="success" size="large" sx={{ my: 2 }} onClick={this.openProDropdown}>
          Download <ExpandMoreIcon sx={proDropdownIconStyles} />
        </MKButton>
        <Menu anchorEl={this.state.proDropdown} open={Boolean(this.state.proDropdown)} onClose={this.closeProDropdown}>
          {this.state.proLinks.map((l) => {
            return (
              <MenuItem onClick={this.closeProDropdown}>
                <Link href={l.link} class="download-link">{l.name}</Link>
              </MenuItem>
            )
          })}
        </Menu>

        <MKTypography display="block" variant="button" color="text" fontWeight="regular">
          {this.state.proVersion ? 'v' + this.state.proVersion : ''}
        </MKTypography>
        <MKTypography display="block" variant="button" color="text" fontWeight="regular">
          <Link to={"/pages/account"} component={RouterLink}>Release notes</Link>
        </MKTypography>
      </Container>)

      downloadOrBuyPersonal = (<Container>
        <MKButton variant="gradient" color="success" size="large" sx={{ my: 2 }} onClick={this.openProDropdown}>
          Download <ExpandMoreIcon sx={proDropdownIconStyles} />
        </MKButton>
        <Menu anchorEl={this.state.proDropdown} open={Boolean(this.state.proDropdown)} onClose={this.closeProDropdown}>
          {this.state.proLinks.map((l) => {
            return (
              <MenuItem onClick={this.closeProDropdown}>
                <Link href={l.link} class="download-link">{l.name}</Link>
              </MenuItem>
            )
          })}
        </Menu>

        <MKTypography display="block" variant="button" color="text" fontWeight="regular">
          {this.state.proVersion ? 'v' + this.state.proVersion : ''}
        </MKTypography>
        <MKTypography display="block" variant="button" color="text" fontWeight="regular">
          <Link to={"/pages/account"} component={RouterLink}>Release notes</Link>
        </MKTypography>
      </Container>)
    }

    return (
      <MKBox component="section" py={{ xs: 12, lg: 18 }}>
        <Container>
          <Grid container item xs={12}>
            <Card sx={{ width: "100%" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} lg={8}>
                  <MKBox py={3} px={4}>
                    <MKTypography variant="h3" mb={1}>
                      Professional
                    </MKTypography>
                    <MKTypography variant="body2" color="text" fontWeight="regular">
                      Designed for people who make money from finding security vulnerabilities, and where it's their full time job. For example professional penetration testers.
                      Comes packed with additional features to automate the boring stuff, find vulnerabilities faster and make life easier.

                      <br /><br />

                      <strong>At this point in time, the primary reason to purchase this edition is to support ongoing development and to get access to new features as soon as they are released.</strong>
                    </MKTypography>
                    <Grid container item xs={12} lg={3} sx={{ mt: 3, mb: 1 }}>
                      <MKTypography variant="h6">What&apos;s included</MKTypography>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            All features from Personal
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Scripts for finding more classes of vulnerabilities (on roadmap)
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Workflow Management (on roadmap)
                          </MKTypography>
                        </MKBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Priority Support
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Early access to the Windows release (once ready)
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Support the ongoing development
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <MKBox p={3} textAlign="center">
                    {downloadOrBuyPro}
                  </MKBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Container>

        <Container>
          <Grid container item xs={12} mt={12}>
            <Card sx={{ width: "100%" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} lg={8}>
                  <MKBox py={3} px={4}>
                    <MKTypography variant="h3" mb={1}>
                      Personal
                    </MKTypography>
                    <MKTypography variant="body2" color="text" fontWeight="regular">
                      Designed for people who make money from finding security vulnerabilities, but it isn't their primary job. For example bug bounty hunters, CTF players, security advocates within organisations, etc.
                      Comes packed with additional features to automate the boring stuff and find vulnerabilities faster.
                    </MKTypography>
                    <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                      <MKTypography variant="h6">What&apos;s included</MKTypography>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            All features from community
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Match/replace within requests
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Scripts for reconnaissance and finding authentication/authorisation vulnerabilities
                          </MKTypography>
                        </MKBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Professional Support
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Define scopes
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Custom scripting
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <MKBox p={3} textAlign="center">
                    {downloadOrBuyPersonal}
                  </MKBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Container>

        <Container>
          <Grid container item xs={12} mt={12}>
            <Card sx={{ width: "100%" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} lg={8}>
                  <MKBox pt={3} px={4}>
                    <MKTypography variant="h3" mb={1}>
                      Community/Open Source
                    </MKTypography>
                    <MKTypography variant="body2" color="text" fontWeight="regular">
                      Contains all of the essentials to get started. Great for people learning or getting started with penetration testing.
                    </MKTypography>
                    <Grid container item xs={12} lg={3} sx={{ mt: 6, mb: 1 }}>
                      <MKTypography variant="h6">What&apos;s included</MKTypography>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Intercept and modify HTTPS traffic
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Websocket and Out of band support
                          </MKTypography>
                        </MKBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Render requests
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" py={1} pr={1} lineHeight={0}>
                          <MKTypography variant="body1" color="dark">
                            <DoneIcon sx={{ fontWeight: "bold" }} />
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
                            Inject payloads into requests
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </MKBox>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <MKBox p={3} textAlign="center">
                    <MKButton variant="gradient" color="success" size="large" sx={{ my: 2 }} onClick={this.openCommunityDropdown}>
                      Download <ExpandMoreIcon sx={communityDropdownIconStyles} />
                    </MKButton>
                    <Menu anchorEl={this.state.communityDropdown} open={Boolean(this.state.communityDropdown)} onClose={this.closeCommunityDropdown}>
                      {this.state.communityLinks.map((l) => {
                        return (
                          <MenuItem onClick={this.closeCommunityDropdown}>
                            <Link href={l.link} class="download-link">{l.name}</Link>
                          </MenuItem>
                        )
                      })}
                    </Menu>

                    <MKTypography display="block" variant="button" color="text" fontWeight="regular">
                      {this.state.communityVersion ? 'v' + this.state.communityVersion : ''}
                    </MKTypography>
                    <MKTypography display="block" variant="button" color="text" fontWeight="regular">
                      <Link href="https://github.com/forensant/pakiki-frontend-gtk/releases">Release notes</Link>
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>

              <MKBox pb={3} px={4}>
                <InstallInstructions version={this.state.communityVersion} edition="Community" headerSize="h6" />
              </MKBox>
            </Card>
          </Grid>
        </Container>

        <Container sx={{ display: 'None' }}>
          <Grid container item xs={12} mt={12}>
            <Card sx={{ width: "100%" }}>

              <MKBox pt={3} px={4}>
                <MKTypography variant="h3" mb={1}>
                  Feature Comparison
                </MKTypography>

                <MKTypography variant="body2" mb={3}>
                  <table>
                    <tr>
                      <th></th>
                      <th>Web Frontend</th>
                      <th>Community</th>
                      <th>Personal</th>
                      <th>Professional</th>
                    </tr>

                    <tr>
                      <td><strong>OS</strong></td>
                    </tr>

                    <tr>
                      <td>Linux</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>

                    <tr>
                      <td>MacOS</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>

                    <tr>
                      <td>Windows</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td>On Roadmap</td>
                      <td>On Roadmap</td>
                      <td>On Roadmap</td>
                    </tr>

                    <tr>
                      <td><strong>Proxy Protocols</strong></td>
                    </tr>
                    <tr>
                      <td>HTTP</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>
                    <tr>
                      <td>WebSockets</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>
                    <tr>
                      <td>Out of Band Interactions</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>



                    <tr>
                      <td><strong>Intercept/manpulate</strong></td>
                    </tr>

                    <tr>
                      <td>HTTP</td>
                      <td></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>
                    <tr>
                      <td>WebSockets</td>
                      <td></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>

                    <tr>
                      <td><strong>Requests/Responses</strong></td>
                    </tr>

                    <tr>
                      <td>Make Manual Requests</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>

                    <tr>
                      <td>Compare Requests/Responses</td>
                      <td></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>

                    <tr>
                      <td>Decode with CyberChef</td>
                      <td></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>

                    <tr>
                      <td>Inject/Fuzz Payloads</td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                      <td><DoneIcon sx={{ fontWeight: "bold" }} /></td>
                    </tr>

                  </table>
                </MKTypography>
              </MKBox>

            </Card>
          </Grid>
        </Container>
      </MKBox>
    )
  }
}

export default Editions;
