import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import React from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

class InstallInstructions extends React.Component {
    constructor(props) {
      super(props);

      const isMacOS = window.navigator.userAgent.indexOf('Mac') !== -1
      const isWindows = window.navigator.userAgent.indexOf('Windows') !== -1

      var activeTab = 0;
      if (isMacOS) {
        activeTab = 1;
      } else if (isWindows) {
        activeTab = 2;
      }

      this.state = {
        architecture: 'x64',
        activeTab: activeTab,
      }
  
      try {
        navigator.userAgentData.getHighEntropyValues(['architecture', 'bitness']).then((ua) => {
          var arch = ua.architecture + ua.bitness;
          if(arch !== 'arm64') {
            arch = 'x64'
          }
  
          this.setState({
            architecture: arch
          });
        });
      } catch (e) {
        console.log("Error getting architecture: " + e)
      }

      this.handleTabType = this.handleTabType.bind(this);
    }

    handleTabType({currentTarget}, newValue) {
      console.log(currentTarget.id);
      this.setState({
        activeTab: newValue,
        tabType: currentTarget.id
      });
    }

    installInstructions() {
      console.log(this.state.activeTab);

      if(this.state.activeTab === 1) {
        return this.macOSInstructions();
      }

      if(this.state.activeTab === 2) {
        return this.windowsInstructions();
      }

      return this.linuxInstructions();
    }

    linuxInstructions() {
      return <>
        <MKTypography variant="body2" mb={1} mt={3}>
          This has primarily been tested on Kali. If you run into bugs on other common distributions, please contact <Link href="mailto:support@pakikiproxy.com">support@pakikiproxy.com</Link>.<br/>
          To install on {this.state.architecture} Kali:
        </MKTypography>
        <MKTypography variant="body2" mb={3}>
          <MKBox
            color="white"
            bgColor="dark"
            opacity={1}
            p={2}
            mb={2}
          >
            <pre>
              # update the system<br/>
              sudo apt update -y<br/>
              sudo apt upgrade -y<br/>
              <br/>

              # download the installer<br/>
              wget https://storage.googleapis.com/forensant-repository/Downloads/Pakiki-Proxy-{this.props.edition}-v{this.props.version}-Linux-{this.state.architecture}.deb<br/>
              <br/>

              # install the package<br/>
              sudo dpkg -i Pakiki-Proxy-{this.props.edition}-v{this.props.version}-Linux-{this.state.architecture}.deb<br/>
              <br/>

              # install any missing dependencies<br/>
              sudo apt install -fy<br/>
              <br/>

              # install chromium (optional - used to launch a preconfigured browser)<br/>
              sudo apt install -y chromium<br/>
            </pre>
          </MKBox>
            
          Pākiki can now be launched via your command launcher or on the command line with: &nbsp;<code>{this.props.edition === "Community" ? "pakiki" : "pakikipro"}</code>
        </MKTypography>
      </>
    }

    macOSInstructions() {
      return <>
        <MKTypography variant="body2" mb={3} mt={3}>
          The MacOS version was recently changed to take advantage of MacOS Sonoma features. As such, it will only run on this version of MacOS. If you encounter any bugs, please contact <a href="mailto:support@pakikiproxy.com">support@pakikiproxy.com</a>.
          <br/><br/>
          <ol>
            <li>Download the dmg file above.</li>
            <li>Open it and drag the application to your Applications folder.</li>
            <li>Pākiki can now be launched from your Applications folder or from Spotlight.</li>
          </ol>  
        </MKTypography>
      </>
    }

    windowsInstructions() {
      return <>
        <MKTypography variant="body2" mb={1} mt={3}>
          A native Windows is planned, however Pākiki does run on Windows Subsystem for Linux.
          This has primarily been tested on Kali. If you run into bugs or need any support, please contact <Link href="mailto:support@pakikiproxy.com">support@pakikiproxy.com</Link>.<br/>
          <br/>
          To update WSL and install Kali, from an elevated PowerShell prompt run:
        </MKTypography>
        <MKTypography variant="body2" mb={3}>
          <MKBox
            color="white"
            bgColor="dark"
            opacity={1}
            p={2}
            mb={2}
          >
            <pre>
              # install and/or update WSL<br/>
              wsl --install<br/>
              wsl --update<br/>
              <br/>
              # install Kali<br/>
              wsl --install -d Kali-linux
            </pre>
          </MKBox>
        </MKTypography>

        <MKTypography variant="body2" mb={1} mt={3}>
          From within a Kali console:
        </MKTypography>

        <MKTypography variant="body2" mb={3}>
          <MKBox
            color="white"
            bgColor="dark"
            opacity={1}
            p={2}
            mb={2}
          >
            <pre>
              # update the system<br/>
              sudo apt update -y<br/>
              sudo apt upgrade -y<br/>
              <br/>

              # install the WSL-specific dependencies<br/>
              sudo apt install libglib2.0-bin<br/>
              sudo apt install dbus-x11<br/>
              <br/>

              # change to the home directory, where you will have write access<br/>
              cd ~<br/>
              <br/>

              # download the installer<br/>
              wget https://storage.googleapis.com/forensant-repository/Downloads/Pakiki-Proxy-{this.props.edition}-v{this.props.version}-Linux-{this.state.architecture}.deb<br/>
              <br/>

              # install the package<br/>
              sudo dpkg -i Pakiki-Proxy-{this.props.edition}-v{this.props.version}-Linux-{this.state.architecture}.deb<br/>
              <br/>

              # install any missing dependencies<br/>
              sudo apt install -fy<br/>
              <br/>

              # install chromium (optional - used to launch a preconfigured browser)<br/>
              sudo apt install -y chromium<br/>
            </pre>
          </MKBox>
            
          Pākiki can now be launched via the start menu or on the command line with: &nbsp;<code>{this.props.edition === "Community" ? "pakiki" : "pakikipro"}</code>. It will also appear on the Windows start bar.
        </MKTypography>
      </>
    }
  
    render() {
      var instructions = this.installInstructions();

      return (
        <>
          <MKTypography variant={this.props.headerSize} mb={1} xs={12} lg={11} mt={5}>
            Install instructions
          </MKTypography>

          <AppBar position="static">
            <Tabs value={this.state.activeTab} onChange={this.handleTabType}>
              <Tab id="linux" label="Linux" />
              <Tab id="macos" label="MacOS" />
              <Tab id="windows" label="Windows (WSL)" />
            </Tabs>
          </AppBar>

          {instructions}
        </>
      );
    }
  }
  
  export default InstallInstructions;
  