/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";

import CloseIcon from "@mui/icons-material/Close";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";


function SimpleBlogCard({ imageLinux, imageMacOS, fullImageLinux, fullImageWeb, fullImageMacOS, title, description, action }) {
  const isMacOS = window.navigator.userAgent.indexOf('Mac') !== -1 && fullImageMacOS;
  
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [activeTab, setActiveTab] = useState(isMacOS ? 1 : 0);
  const handleTabType = (event, newValue) => setActiveTab(newValue);

  /*if(fullImageLinux != null) {
    const img = new Image();
    img.src = fullImageLinux;
  }

  if(fullImageWeb != null) {
    const img = new Image();
    img.src = fullImageWeb;
  }

  if(fullImageMacOS != null) {
    const img = new Image();
    img.src = fullImageMacOS;
  }*/

  var bigImage = fullImageLinux;
  if(activeTab === 1) { // MacOS
    bigImage = fullImageMacOS;
  } else if (activeTab === 2) { // Web
    bigImage = fullImageWeb;
  }

  if(fullImageLinux == null) {
    bigImage = fullImageWeb;
  }

  var image = imageLinux;
  if(isMacOS && imageMacOS) {
    image = imageMacOS;
  }

  // all three by default
  var tabs = <Grid container item justifyContent="center" xs={12} lg={4} mx="auto">
      <AppBar position="static">
        <Tabs value={activeTab} onChange={handleTabType}>
          <Tab label="Linux" />
          <Tab label="MacOS" />
          <Tab label="Web" />
        </Tabs>
      </AppBar>
    </Grid>

  if(fullImageWeb == null) {
    tabs = <Grid container item justifyContent="center" xs={12} lg={4} mx="auto">
      <AppBar position="static">
        <Tabs value={activeTab} onChange={handleTabType}>
          <Tab label="Linux" />
          <Tab label="MacOS" />
        </Tabs>
      </AppBar>
    </Grid>
  }

  if(fullImageLinux == null) {
    tabs = <></>
  }

  return (
    <Card>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={200}>
          <MKBox
            position="relative"
            maxWidth="1332px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{title}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>

            {tabs}

            <MKBox
              className="screenshot"
              component="img"
              src={bigImage}
              alt={title}
              borderRadius="lg"
              shadow="md"
              position="relative"
              height="70vh"
              width="90vw"
              maxWidth="1212px"
              zIndex={1}
              style={{objectFit: 'scale-down'}}
              p={2}
            />
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                close
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKButton onClick={toggleModal} variant="text" sx={{padding: "0 0 10px 0"}}>
          <MKBox
            component="img"
            src={image}
            alt={title}
            borderRadius="lg"
            shadow="md"
            position="relative"
            width="100%"
            loading="lazy"
            zIndex={1}
          />
          <MKBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MKButton>
      </MKBox>
      <MKBox p={3} mt={-2}>
        <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>

        <MKButton
            onClick={toggleModal}
            variant="outlined"
            size="small"
            color="dark"
          >
            View
          </MKButton>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SimpleBlogCard.propTypes = {
  imageLinux: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default SimpleBlogCard;
