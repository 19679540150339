// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 PRO React examples
import Table from "examples/Tables/Table";
import React from "react";

const axios = require('axios').default;

function dateStr(dte) {
  var lastUsedDate = new Date(Date.parse(dte))
  var lastUsedStr = ''
  if(lastUsedDate.getTime() / 1000 !== 0) {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    lastUsedStr = lastUsedDate.getDate().toString() + ' ' + months[lastUsedDate.getMonth()] + ' ' + lastUsedDate.getFullYear().toString();
  }

  return lastUsedStr;
}

function ActivationsTable(props) {
  if(props.activations == null || props.activations.length <= 0) {
    return <MKTypography variant="body2">You do not have any activations.</MKTypography>
  }
  let rows = [];

  props.activations.forEach((a) => {
    rows.push(
      {
        os: (<MKTypography variant="caption" color="text">
          {a['OS']}
        </MKTypography>),
        'activated on': (
          <MKTypography variant="caption" color="secondary">
            {dateStr(a['ActivatedOn'])}
          </MKTypography>
        ),
        'last used': (
          <MKTypography variant="caption" color="secondary">
            {dateStr(a['LastUsed'])}
          </MKTypography>
        ),
        actions: (
          <Container>
            {
              !props.updatingActions.includes('' + a['ID']) ? '' :
              <MKTypography
                variant="body"
                color="secondary"
                fontWeight="medium"
                name={a['ID']}
                action="deactivate"
                onClick={props.deactivateActivation}
                >
                  Deactivate
              </MKTypography>
            }

            {
              props.updatingActions.includes('' + a['ID']) ? '' :
              <MKTypography
                component="a"
                href="#"
                variant="body"
                color="secondary"
                fontWeight="medium"
                name={a['ID']}
                action="deactivate"
                onClick={props.deactivateActivation}
                >
                  Deactivate
              </MKTypography>
            }
          </Container>
        ),
      });
    });

  let columns = [
      { name: "os", align: "left" },
      { name: "activated on", align: "left" },
      { name: "last used", align: "left" },
      { name: "actions", align: "center" },
    ];

  return (<Table columns={columns} rows={rows} />)
}

function ManualActivation(props) {
  if(props.activations_remaining <= 0) {
    return ""
  }

  let activationResponse = '';
  if (props.activationResponse !== '') {
    activationResponse = <Grid pt={2} container item><div>Activation response:</div><div>{props.activationResponse}</div></Grid>;
  }

  return (
    <Container>
        <Grid container item xs={12} lg={11} mx="auto">
          <MKTypography variant="body2" py={2} xs={12} lg={11} text-align="right">
            To manually activate an instance, enter the activation string generated by the desktop client here:<br/><br/>
            <form onSubmit={props.onManualActivation}>
              <Grid container item>
                <MKInput type="text" label="Activation String" name="activation_req" />
                &nbsp;&nbsp;
                <MKButton color="success" type="submit">Activate Now</MKButton>
              </Grid>
            </form>
            {activationResponse}
          </MKTypography>
        </Grid>
      </Container>
  )
}

function Activations(props) {
  if(props.licence == null) {
    return "";
  }

  let activations_remaining = props.licence.MaximumActivations;
  if(props.licence.Activations != null) {
    activations_remaining -= props.licence.Activations.length;
  }

  return (
    <div>
      <Container>
        <Grid container item xs={12} lg={11} mx="auto">
          <MKTypography variant="h4" textGradient py={2} xs={12} lg={11}>
            Activations
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={12} lg={11} mx="auto">
          <ActivationsTable activations={props.licence.Activations} deactivateActivation={props.deactivateActivation} updatingActions={props.updatingActions} />
        </Grid>
      </Container>

      <ManualActivation onManualActivation={props.onManualActivation} activationResponse={props.activationResponse} />

      <Container>
        <Grid container item xs={12} lg={11} mx="auto">
          <MKTypography variant="body2" textGradient py={2} xs={12} lg={11} text-align="right">
            {activations_remaining} activations remaining.
          </MKTypography>
        </Grid>
      </Container>
    </div>
  );
}

function NoLicenceNoOrganisation() {
  return  <MKTypography variant="body2" xs={12} lg={11} mb={2}>
            You do not currently have a licence.
          </MKTypography>
}

function NoLicenceWithOrganisation(props) {
  const organisation = props.organisation;
  
  return  <MKTypography variant="body2" xs={12}  lg={11} mb={2}>
            You do not currently have a licence. Your subscription is managed by {organisation['Name']}. Please contact your administrator to have a licence assigned.
          </MKTypography>
}

function ExistingLicence(props) {
  const licence = props.licence;
  const organisation = props.organisation;
  const edition = licence.Edition;

  let exp = new Date(licence['Expiry']);
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  let expiry = '' + days[exp.getDay()] + ' ' + exp.getDate() + ' ' + months[exp.getMonth()] + ' ' + exp.getFullYear();

  let organisation_text = '';
  if(organisation != null) {
    organisation_text = 'It is managed by ' + organisation['Name'] + '.';
  }

  let admin = ''
  if(props.account.SuperAdmin) {
    let url = "https://console.cloud.google.com/datastore/entities;kind=Licence;ns=__$DEFAULT$__/edit;key=0%252F%257C7%252FLicence%257C19%252Fid%253A" + licence.ID + "?project=forensant-portal" + (window.env == "Development" ? '-test' : '')
    admin = <a href={url}>Admin</a>
  }

  var has_expired = (exp.getTime() / 1000 < Date.now() / 1000);
  var grace_period = ''

  let expiry_text = 'expires';
  if(has_expired) {
    expiry_text = 'expired';
    grace_period = 'Please renew your subscription now or update your payment details below to continue to have access to Pākiki Proxy.'
  }

  let upgrade = <></>

  if(organisation == null) {
    if(licence.Lifetime) {
      // Don't allow upgrades for lifetime licences
    }
    else if(edition == "Professional") {
      upgrade = <MKTypography
        component="span"
        variant="body2"
        color="secondary"
        fontWeight="regular"
        >
          <MKTypography
            component="a"
            variant="body2"
            color="primary"
            fontWeight="regular"
            mr={1}
            href="#"
            onClick={props.toggleSubscription}
            >
              Downgrade to Personal
          </MKTypography>
          This will take effect immediately, and your next subscription renewal will be at the personal price ($99 USD per year).
        </MKTypography>
    } else {
      upgrade = <>
      <MKButton color="success" type="submit" sx={{'margin-top': '20px'}} onClick={props.toggleSubscription}>Upgrade to Professional</MKButton>
      <MKTypography
        mt={2}
        variant="body2"
        color="secondary"
        fontWeight="regular"
        >
          This will be prorated (for example, if you have 6 months remaining until your subscription renews, you'll be charged for 6 months of Professional). Your next subscription renewal will be at the full Professional price ($399 USD per year).
        </MKTypography>
        </>
    }
  }

  return  <>
            <MKTypography variant="body2" xs={12}>
              You have a <strong>{edition}</strong> licence which {expiry_text} on {expiry}. {organisation_text} {grace_period} {admin} 
            </MKTypography>
            {upgrade}
          </>
}

function Licence(props) {
  const licence = props.licence;
  const organisation = props.organisation;

  if(licence == null && organisation == null) {
    return <NoLicenceNoOrganisation />;
  }

  if(licence == null && organisation != null) {
    return <NoLicenceWithOrganisation organisation={organisation} />
  }

  return <ExistingLicence
    account={props.account}
    licence={licence}
    organisation={organisation}
    deactivateActivation={props.deactivateActivation}
    toggleSubscription={props.toggleSubscription}
  />
}

class Licencing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ActivationResponse: '',
      UpdatingActions: [],
      error: '',
      processingUpgrade: false,
    };

    this.handleManualActivation = this.handleManualActivation.bind(this);
    this.deactivateActivation = this.deactivateActivation.bind(this);
    this.toggleSubscription = this.toggleSubscription.bind(this);
  }

  deactivateActivation(e) {
    e.preventDefault();

    //const action = target.getAttribute('action');

    const target = e.target;
    const id = target.name;
    let vm = this;

    const form = new FormData();
    form.append('id', id)
    console.log("Deactivating licence: " + id);

    let updatingActionsArray = this.state.UpdatingActions;
    updatingActionsArray.push(id);

    this.setState({
      updatingActions: updatingActionsArray
    });

    axios.post('/api/activation/deactivate', form, { withCredentials: true })
      .then(function (response) {
        vm.props.updateAccountFromServer ();

      })
      .catch((error) => {
        // handle error
        console.log("Error:");
        console.log(error);
        vm.setState({
          'error': error.response.data
        });
        //console.log(this.state)
      })
      .then(function () {
        let updateArray = vm.state.updatingActions;
        let index = updateArray.indexOf(id);
        if (index > -1) {
          updateArray.splice(index, 1);
        }

        vm.setState({
            updatingActionsArray: updateArray
        });   
      });

    return false
  }

  toggleSubscription(e) {
    e.preventDefault();
    if(this.state.processingUpgrade) {
      return false;
    }
    
    let vm = this;

    const form = new FormData();
    var prevEdition = vm.props.account.Licence.Edition;
    var errorOccurred = false;

    vm.setState({
      processingUpgrade: true,
    });
    
    vm.props.awaitPaymentFunc((account) => {
      return vm.state.processingUpgrade == false && account.Licence !== null && account.Licence.Edition !== prevEdition;
    });

    axios.post('/api/subscription/toggle_edition', form, { withCredentials: true })
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log("Error:");
        console.log(error);
        errorOccurred = true;
        vm.setState({
          error: 'Error: ' + error.response.data
        });
      })
      .then(() => {
        vm.setState({
          processingUpgrade: false,
        });
      })
      
    return false;
  }

  handleManualActivation(e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.
    let activation_req = e.target.elements.activation_req.value;
    console.log("Activation req: " + activation_req);

    let vm = this;
    const FormData = require('form-data');
 
    const form = new FormData();
    form.append('req', activation_req);

    axios.post('/api/activation/register', form, { withCredentials: true })
      .then(function (response) {
        // handle success
        //console.log(navigation);
        console.log(response);

        // TODO: fully update in real-time
        vm.setState({
          ActivationResponse: response.data
        });

        //window.sessionStorage.setItem('account', JSON.stringify(response.data));

      })
      .catch((error) => {
        // handle error
        console.log("Error:");
        console.log(error);
        vm.setState({
          ActivationResponse: 'Error: ' + error.response.data
        });
        //console.log(this.state)
      })
      .then(function () {
        // always executed
      });

    return false;

  }

  render() {
    let account = this.props.account;
    console.log(account);

    if(account == null || !('Licence' in account)) {
      return ''
    }

    //try {
      let licence = account['Licence'];
      let organisation = account['Organisation'];
    //}
    //catch (err) {
    //  console.log("Error parsing licence: " + err)
    //}

    return (
      <MKBox component="section" id="licence">
        <Container>
          <Grid container item xs={12} lg={11} mx="auto" mt={3}>
            <MKTypography variant="h3" textGradient py={2} xs={12} lg={11}>
              Your License
            </MKTypography>
          </Grid>

          <Grid container item xs={12} lg={11} mx="auto">
            <MKTypography variant="body1" color="text" xs={12}  lg={11} mb={2}>
              <Licence account={account} licence={licence} organisation={organisation} toggleSubscription={this.toggleSubscription} />
            </MKTypography>
          </Grid>

          <Grid container item xs={12} lg={11} mx="auto">
            <MKTypography variant="body2" color="error" xs={12}  lg={11} mb={this.state.error == '' ? 0 : 2}>
              {this.state.error}
            </MKTypography>
          </Grid>
        </Container>

        <Activations licence={licence}
            onManualActivation={this.handleManualActivation}
            activationResponse={this.state.ActivationResponse}
            updatingActions={this.state.UpdatingActions}
            deactivateActivation={this.deactivateActivation} />
      </MKBox>
    );
  }
}

export default Licencing;