// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

import BasePage from "pages/LandingPages/Proximity/base_page"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

class Pricing extends BasePage {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      tabType: "monthly"
    }

    this.handleTabType = this.handleTabType.bind(this);
  }

  handleTabType({currentTarget}, newValue) {
    this.setState({
      activeTab: newValue,
      tabType: currentTarget.id
    });
  }

  render() {
    return (
      <MKBox component="section" variant="gradient" bgColor="dark" py={{ xs: 0, lg: 7 }} id="pricing">
        <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            md={8}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h3" color="white" mb={2}>
              Pricing
            </MKTypography>
            <MKTypography variant="body2" color="white">
              The paid versions are currently in <strong>early access</strong>. Support the development, and gain access to exciting new features as they are developed.
            </MKTypography>

            <MKTypography
                component="a"
                href="#about_us"
                variant="button"
                color="success"
                fontWeight="regular"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: "translateX(3px)",
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: "translateX(6px)",
                  },
                }}
              >
                More about us
                <ArrowForwardIcon sx={{ fontWeight: "bold" }}></ArrowForwardIcon>
              </MKTypography>
          </Grid>
        </Container>
        <MKBox mt={-16}>
          <Container>
            <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={4}>
                  <DefaultPricingCard
                    badge={{ color: "light", label: "community" }}
                    price={{ currency: "", value:    '', type: "Open Source" }}
                    specifications={[
                      { label: "Intercept Traffic", includes: true },
                      { label: "Supports Common Web Protocols", includes: true },
                      { label: "Basic Automation", includes: true },
                      { label: "Community Support", includes: true },
                      { label: "Professional Support", includes: false },
                      { label: "Advanced Automation and Reporting", includes: false },
                      { label: "Custom Scripting", includes: false },
                    ]}
                    action={{
                      type: "internal",
                      route: "/pages/downloads",
                      color: "dark",
                      label: "Download Now",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DefaultPricingCard
                    color="dark"
                    badge={{ color: "success", label: "personal" }}
                    price={{ currency: "$", value: 99, type: "yr" }}
                    specifications={[
                      { label: "Everything From Community", includes: true },
                      { label: "Professional Support", includes: true },
                      { label: "Advanced Automation and Reporting", includes: true },
                      { label: "Custom Scripting", includes: true },
                      { label: "Scopes", includes: true },
                      { label: "Match/Replace Within Requests", includes: true },
                    ]}
                    action={{
                      type: "internal",
                      route: "/pages/purchase?edition=Personal",
                      color: "success",
                      label: "buy now",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DefaultPricingCard
                    badge={{ color: "light", label: "professional" }}
                    price={{ currency: "$", value: 399, type: 'yr' }}
                    specifications={[
                      { label: "Everything From Personal", includes: true },
                      { label: "Priority Professional Support", includes: true },
                      { label: "Support Future Development", includes: true },
                      { label: "Early Access to Windows Build (when available)", includes: true },
                      { label: "Additional Build-In Scripts (on roadmap)", includes: true },
                      { label: "Workflow Management (on roadmap)", includes: true },
                      
                    ]}
                    action={{
                      type: "internal",
                      route: "/pages/purchase?edition=Professional",
                      color: "dark",
                      label: "buy now",
                    }}
                  />
                </Grid>
              </Grid>
            </MKBox>
          </Container>
        </MKBox>
      </MKBox>
    );
  }
}

export default Pricing;