import React from "react";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import ArticleIcon from '@mui/icons-material/Article';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const axios = require('axios').default;

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let menu = [
      {
        name: "Product",
        icon: <StarIcon></StarIcon>,
        collapse: [
          {
            name: "Features",
            route: {
              pathname: '/pages/landing-pages/pakiki',
              hash: '#features',
            },
          },
          {
            name: "Pricing",
            route: {
              pathname: '/pages/landing-pages/pakiki',
              hash: '#pricing',
            },
          },
          {
            name: "Downloads",
            route: {
              pathname: '/pages/downloads',
            },
          },
        ],
      },
      {
        name: "Docs",
        icon: <MenuBookIcon></MenuBookIcon>,
        action: "external",
        href: "https://docs.pakikiproxy.com/"
      },
      {
        name: "Blog",
        icon: <ArticleIcon></ArticleIcon>,
        action: "external",
        href: "https://pakiki.co.nz/blog/"
      },
    ];

    let account = this.props.account;
    if(!account) {
      const accountJson = sessionStorage.getItem('account');
      try {
        account = JSON.parse(accountJson)
      }
      catch (err) {
        console.log("Could not parse json: " + err)
      }
    }

    let accountExists = false;
    let signedIn = false;

    if(account) {
      menu = this.addAccountToMenu(menu, account);
      accountExists = true;
      signedIn = 'Name' in account;
    }

    let updateFunc = this.props.updateAccount;
    if(!updateFunc) {
      updateFunc = (acc) => {
        if(this.state.accountExists !== true) {
          this.setState({
            menu: this.addAccountToMenu(this.state.menu, acc),
            accountExists: true,
            signedIn: 'Name' in acc,
          });
        }
      }
    }
    
    this.state = {
      accountExists: accountExists,
      menu: menu,
      signedIn: signedIn,
    };
    this.populateAccountDetails(updateFunc);

    this.signout = this.signout.bind(this);
  }

  signout(vm) {
    let navigate = vm.props.navigate;
    axios.post('/api/accounts/logout', {}, {
      withCredentials: true,
      validateStatus: function(status) {
        let statuses = [200, 301, 302, 401];
        return statuses.indexOf(status) !== -1;
      }
    })
    .then(function (response) {
        // handle success
        window.sessionStorage.setItem('account', '{"state": "unauthenticated"}')
        console.log("Function called!");

        // add the sign in link to the menu
        let m = vm.state.menu;
        m.pop();

        vm.setState({
            menu: m
        });

        navigate('/');
    })
    .catch((error) => {
        console.log(error);
    })
  }

  addAccountToMenu(menu, account_details) {
    if('Name' in account_details) {
        var collapsable = [
          {
              name: "Download",
              route: {
                pathname: '/pages/account',
                hash: '#downloads',
              }
          },
          {
              name: "License/Activations",
              route: {
                pathname: '/pages/account',
                hash: '#licence',
              }
          },
          {
              name: "Sign out",
              onClick: () => this.signout(this),
          },
        ];

        if(account_details.SuperAdmin) {
          menu.push({
            name: "Admin",
            icon: <AdminPanelSettingsIcon></AdminPanelSettingsIcon>,
            collapse: [
              {
                  name: "Releases",
                  route: {
                    pathname: '/pages/admin/releases'
                  }
              },
              {
                  name: "Statistics",
                  route: {
                    pathname: '/pages/admin/stats'
                  }
              },
              {
                name: "Products & Coupons",
                route: {
                  pathname: '/pages/admin/coupons'
                }
              },
              {
                name: "Users",
                route: {
                  pathname: '/pages/admin/users'
                }
              },
              {
                name: "Organisations",
                route: {
                  pathname: '/pages/admin/organisations'
                }
              },
            ]
          });
        }

        // add account to menu
        menu.push({
            name: "Welcome " + account_details.Name,
            icon: <PersonIcon></PersonIcon>,
            collapse: collapsable,
      });
    }
    else {
        // add login option
        menu.push({
          name: "Account login",
          icon: <PersonIcon></PersonIcon>,
          route: "/authentication/sign-in",
        });
    }

    this.setState({
      accountExists: true
    });

    return menu;
  }

  populateAccountDetails(updateAccount) {
    let vm = this;
    axios.get('/api/accounts/details', { withCredentials: true })
        .then(function (response) {
            // handle success
            let accountObj = JSON.stringify(response.data);
            window.sessionStorage.setItem('account', accountObj);
            updateAccount(response.data);
            if(!vm.state.accountExists) {
              vm.setState({
                menu: vm.addAccountToMenu(vm.state.menu, response.data),
                accountExists: true,
                signedIn: 'Name' in response.data,
              });
            }
        })
        .catch((error) => {
            console.log(error);
            if(error.response.status === 401) {
                window.sessionStorage.setItem('account', '{"state": "unauthenticated"}')
            }
            updateAccount({state: 'unauthenticated'});
        })
  }

  componentDidUpdate(prevProps) {
    if(!prevProps.account && this.props.account) {
      let newMenu = this.addAccountToMenu(this.state.menu, this.props.account);
      this.setState({
        menu: newMenu,
      });
    }
  }

  render() {
    return (
      <>
        <DefaultNavbar
          routes={this.state.menu}
          action={{
            type: "internal",
            route: this.state.signedIn ? '/pages/account#downloads' : '/pages/downloads',
            label: this.state.signedIn ? 'Download Now' : 'Download/Buy Now',
            color: "success",
          }}
          sticky
          transparent={this.props.transparent ? true : false}
          light={this.props.transparent ? true : false}
        />
      </>
    );
  }
}

export default Navbar;
