/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import MarkdownView from 'react-showdown';
import React from "react";

import InstallInstructions from "pages/Account/sections/InstallInstructions";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const axios = require('axios').default;

class Downloads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      version: '',
      changelog: '',
      downloads: '',
      proDropdown: null
    }

    this.fetchRelease()

    this.openProDropdown = this.openProDropdown.bind(this);
    this.closeProDropdown = this.closeProDropdown.bind(this);
  }

  fetchRelease() {
    let vm = this;
    axios.get("/api/versions/latest", { withCredentials: true })
      .then(function (response) {
        response.data.forEach((r) => {
          if (r.Edition === "Professional") {
            let links = []
            try {
              links = JSON.parse(r.Downloads)
            } catch (e) {
              console.log("Error parsing downloads: " + e)
            }

            console.log(links)

            vm.setState({
              version: r.Version,
              changelog: r.ChangeLog,
              downloads: links,
            })
          }
        })
      })
      .catch((error) => {
        // handle error
        console.log(error)
      })
  }

  openProDropdown(e) {
    this.setState({
      proDropdown: e.target
    });
  }

  closeProDropdown() {
    this.setState({
      proDropdown: null
    });
  }

  render() {
    const iconStyles = {
      ml: 1,
      fontWeight: "bold",
      transition: "transform 200ms ease-in-out",
    };

    const proDropdownIconStyles = {
      transform: this.state.proDropdown ? "rotate(180deg)" : "rotate(0)",
      ...iconStyles,
    };

    return (
      <MKBox component="section" pt={12} id="downloads">
        <Container>
          <Grid
            container
            item
            mx="auto"
            xs={12}
            lg={11}
            sx={{ textAlign: "left" }}
          >
            <MKTypography variant="h3" mt={3} xs={12} lg={11}>
              Download and Install
            </MKTypography>
          </Grid>
          <Grid container item xs={12} mt={2} lg={11} alignItems="left" mx="auto">
            <Grid item xs={12} md={12}>
              <MKButton variant="gradient" color="success" size="large" sx={{ my: 2 }} onClick={this.openProDropdown}>

                Download <ExpandMoreIcon sx={proDropdownIconStyles} />
              </MKButton>
              <Menu anchorEl={this.state.proDropdown} open={Boolean(this.state.proDropdown)} onClose={this.closeProDropdown}>
                {this.state.downloads.length === 0 ? '' : this.state.downloads.map((l) => {
                  return (
                    <MenuItem onClick={this.closeProDropdown}>
                      <Link href={l.link} class="download-link">{l.name}</Link>
                    </MenuItem>
                  )
                })}
              </Menu>

              <InstallInstructions version={this.state.version} edition="Pro" headerSize="h5" />

              <MKTypography variant="h5" mb={2}>Version {this.state.version} - Release Notes</MKTypography>
              <MKTypography variant="body2">
                <MarkdownView
                  markdown={this.state.changelog}
                  options={{ tables: true, emoji: true }}
                />
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
}

export default Downloads;
