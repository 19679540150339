// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 PRO React examples
import Table from "examples/Tables/Table";
import React from "react";

const axios = require('axios').default;

function UserLicenceExpiry(props) {
    let lic = props.licence;
    if(lic == null) {
        return "No current licence assigned";
    }
    else {
        let exp = new Date(lic['Expiry']);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        let expiry = '' + exp.getDate() + ' ' + months[exp.getMonth()] + ' ' + exp.getFullYear();
        return expiry
    }
}

function Users(props) {
  let rows = [];
  
  props.users.forEach((u) => {
    rows.push({
      name: (<MKTypography ml={2} variant="body" color="text" fontWeight="medium">
        {u['Name']}
        </MKTypography>),
      'licence expiry': (
        <MKTypography variant="body" color="text">
          <UserLicenceExpiry licence={u['Licence']} />
        </MKTypography>
      ),
      'email': (
        <MKTypography variant="body" color="secondary">
          {u['Email']}
        </MKTypography>
      ),
      'account status': (
        <MKTypography variant="body" color="text">
          {u['AccountStatus']}
        </MKTypography>
      ),
      admin: (
        props.updatingAdmin.includes(u['Email']) ? 
          <CircularProgress color="info" size="1.8rem" /> :
          <Checkbox checked={u['OrganisationAdmin']} name={u['Email']} onChange={props.toggleAdmin} />
      ),
      actions: (
        <Container>
          {
            !props.updatingActions.includes(u['Email']) ? '' :
            <CircularProgress color="info" size="1.8rem" />
          }

          {
            props.updatingActions.includes(u['Email']) || u['Licence'] == null ? '' :
            <MKTypography
                component="a"
                href="#"
                variant="body"
                color="secondary"
                fontWeight="medium"
                name={u['Email']}
                action="unassign"
                onClick={props.assignLicence}
                >
                Unassign Licence
            </MKTypography>
          }

          {
            props.updatingActions.includes(u['Email']) || u['Licence'] != null || !props.canAssignLicence ? '' :
            <MKTypography
                component="a"
                href="#"
                variant="body"
                color="secondary"
                fontWeight="medium"
                name={u['Email']}
                action="assign"
                onClick={props.assignLicence}
                >
                Assign Licence
            </MKTypography>
          }
        </Container>
        
      ),
    });
  });

  const columns = [
      { name: "name", align: "left" },
      { name: "email", align: "left" },
      { name: "account status", align: "left" },
      { name: "licence expiry", align: "left" },
      { name: "admin", align: "center" },
      { name: "actions", align: "center" },
  ];

  if(props.users.length >= 1) {
    return (<Table columns={columns} rows={rows} />)
  }
  else {
    return "You do not have any users."
  }

}

class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addUserSubmitEnabled: true,
      addUserError: '',
      addUserName: '',
      addUserEmail: '',
      lifetimeSubmitEnabled: true,
      updatingActions: [],
      updatingAdmin: [],
    };

    this.assignLicence = this.assignLicence.bind(this);
    this.handleAddUserSubmit = this.handleAddUserSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLifetimeSubmit = this.handleLifetimeSubmit.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
  }

  handleAddUserSubmit(e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.
    let vm = this;

    const form = new FormData();
    form.append('name', this.state.addUserName);
    form.append('email', this.state.addUserEmail);

    vm.setState({
      addUserSubmitEnabled: false,
    });

    axios.post('/api/organisation/add_user', form, { withCredentials: true })
      .then(function (response) {
        // handle success
        //console.log(navigation);
        console.log(response);
        window.sessionStorage.setItem('account', JSON.stringify(response.data));
        vm.props.updateAccount(response.data)

        vm.setState({
            addUserEmail: '',
            addUserName: '',
            addUserError: '',
        });

        vm.addUserNameField.getElementsByTagName('input')[0].focus();
      })
      .catch((error) => {
        // handle error
        vm.setState({
          addUserError: error.response.data
        });
      })
      .then(function () {
        vm.setState({
          addUserSubmitEnabled: true,
        });
      });

    e.preventDefault()
    return false
  }

  handleLifetimeSubmit(e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.
    let vm = this;

    const form = new FormData();

    vm.setState({
      lifetimeSubmitEnabled: false,
    });

    axios.post('/api/admin/licences/generate_lifetime', form, { withCredentials: true })
      .then(function (response) {
        // handle success
        //console.log(navigation);
        console.log(response);
        window.sessionStorage.setItem('account', JSON.stringify(response.data));
        vm.props.updateAccount(response.data)
      })
      .catch((error) => {
        // handle error
        vm.setState({
          addUserError: error.response.data
        });
      })
      .then(function () {
        vm.setState({
          lifetimeSubmitEnabled: true,
        });
      });

    return false
  }

  toggleAdmin(event) {
    const target = event.target;
    //const value = target.type === 'checkbox' ? target.checked : target.value;

    const email = target.name;
    let vm = this;

    const form = new FormData();
    form.append('email', email);

    let updatingAdminArray = this.state.updatingAdmin;
    updatingAdminArray.push(email);

    this.setState({
        updatingAdmin: updatingAdminArray
    });

    axios.post('/api/organisation/toggle_user_admin', form, { withCredentials: true })
      .then(function (response) {
        // handle success
        //console.log(navigation);
        console.log(response);
        window.sessionStorage.setItem('account', JSON.stringify(response.data));
        vm.props.updateAccount(response.data)

        vm.setState({
          addUserError: ''
        });

      })
      .catch((error) => {
        // handle error
        vm.setState({
          addUserError: error.response.data
        });
      })
      .then(function () {
        let updateArray = vm.state.updatingAdmin;
        let index = updateArray.indexOf(email);
        if (index > -1) {
          updateArray.splice(index, 1);
        }

        vm.setState({
            updatingAdmin: updateArray
        });    
      });

    event.preventDefault();
    return false
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  assignLicence(event) {
    // TODO: Prompt if the licence has activations assigned
    const target = event.target;
    const action = target.getAttribute('action');

    const email = target.name;
    let vm = this;

    const form = new FormData();
    form.append('email', email);

    let updatingActionsArray = this.state.updatingActions;
    updatingActionsArray.push(email);

    let url = '/api/organisation/assign_licence'
    if(action === "unassign") {
        url = '/api/organisation/unassign_licence'
    }

    this.setState({
        updatingActions: updatingActionsArray
    });

    axios.post(url, form, { withCredentials: true })
      .then(function (response) {
        // handle success
        //console.log(navigation);
        console.log(response);
        window.sessionStorage.setItem('account', JSON.stringify(response.data));
        vm.props.updateAccount(response.data)

        vm.setState({
          addUserError: ''
        });

      })
      .catch((error) => {
        // handle error
        vm.setState({
          addUserError: error.response.data
        });
      })
      .then(function () {
        let updateArray = vm.state.updatingActions;
        let index = updateArray.indexOf(email);
        if (index > -1) {
          updateArray.splice(index, 1);
        }

        vm.setState({
            updatingActionsArray: updateArray
        });    
      });

    event.preventDefault();
    return false;
  }

  render() {
    let account = this.props.account;
    console.log(account);

    if(account == null || 
        !('OrganisationAdmin' in account) || 
        account['OrganisationAdmin'] !== true ||
        account['Organisation'] == null) {
      return ''
    }

    let users = account['Organisation']['Users'];
    let organisation = account['Organisation'];
    
    let unassignedLicText = ''
    let unassignedLics = organisation['UnassignedLicences'];
    if(unassignedLics == null || unassignedLics.length === 0) {
        unassignedLicText = "You have no unassigned licences.";
    }
    else if(unassignedLics.length === 1) {
        unassignedLicText = "You have 1 unassigned licence.";
    }
    else {
        unassignedLicText = "You have " + unassignedLics.length + " unassigned licences.";
    }

    let lifetimeLicenceText = ""
    if(account.SuperAdmin) {
      lifetimeLicenceText = <MKBox component="form" method="" action="" onSubmit={this.handleLifetimeSubmit}>
          <MKButton type="submit" variant="gradient" color="success" disabled={!this.state.lifetimeSubmitEnabled}>
            Generate lifetime licence
          </MKButton>
        </MKBox>
    }

    return (
      <MKBox component="section">
        <Container>
          <Grid container item xs={12} lg={11} mx="auto">
            <MKTypography variant="h3" textGradient py={2} xs={12} lg={11}>
              Users
            </MKTypography>
          </Grid>

          <Grid container item xs={12} lg={11} mx="auto">
            <Users
                assignLicence={this.assignLicence}
                canAssignLicence={unassignedLics != null && unassignedLics.length >= 1}
                toggleAdmin={this.toggleAdmin}
                users={users}
                updatingAdmin={this.state.updatingAdmin}
                updatingActions={this.state.updatingActions}
            />
          </Grid>
          
          <Grid container xs={12} lg={11} mx="auto" mt={3}>
            <Grid item xs={12} lg={8} flexDirection="column" justifyContent="center">
              <MKBox component="form" method="" action="" onSubmit={this.handleAddUserSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <MKInput label="Name" ref={ input => this['addUserNameField'] = input } name="addUserName"  onChange={this.handleChange} value={this.state.addUserName} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <MKInput label="Email" name="addUserEmail" onChange={this.handleChange} value={this.state.addUserEmail} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MKButton type="submit" variant="gradient" color="success" disabled={!this.state.addUserSubmitEnabled} fullWidth sx={{ height: "100%" }}>
                        Add User
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>

            <Grid item xs={12}>
              <MKBox mb={1}>
                <MKTypography variant="button" color="error" fontWeight="bold">
                  {this.state.addUserError}
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
          
          <Grid container xs={12} lg={11} mx="auto">
            <Grid item xs={12} lg={8} flexDirection="column" justifyContent="center">
              <MKBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <MKTypography py={2} xs={12} variant="body2" color="text" >
                      {unassignedLicText}
                    </MKTypography>
                    {lifetimeLicenceText}
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Grid>

        </Container>
      </MKBox>
    );
  }
}

export default UserManagement;