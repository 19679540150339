import { Component } from 'react';

class BasePage extends Component {
    constructor(props) {
      super(props);

      // TODO: This doesn't seem to be working
      this.scrollToElement = this.scrollToElement.bind(this);
    }

    scrollToElement(element_id) {
        let el = document.getElementById(element_id);

        if(el != null) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }
    }
}

export default BasePage;